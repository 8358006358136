import { useMemo } from 'react'
import { useCreditBonusRestrictedGames } from 'state/app/hooks'
import { useActiveBonusInWageringProcess } from 'state/bonus/hooks'

export const useRestrictedGamesC2PBonus = (code: string) => {
  const bonusWagering = useActiveBonusInWageringProcess()
  const creditBonusRestrictedGames = useCreditBonusRestrictedGames()

  return useMemo(
    () => bonusWagering && creditBonusRestrictedGames.includes(code),
    [creditBonusRestrictedGames, code, bonusWagering],
  )
}
