import { ChainIdEnum } from 'config/constants/network'
import { APIEndpointEnum } from 'config/constants/server'
import {
  Referee,
  ReferralBalanceTokenAmount,
  ReferralBonus,
  ReferralCampaign,
  ReferralLiveReward,
  ReferralMetaData,
  ReferralOverviewResponse,
  ReferralTxnBonusClaim,
} from 'config/types/referral'
import { formatToApiNetworkField } from 'utils'
import { formatFitlerTransaction } from 'utils/transaction'
import { ReferralBonusFilterProps, ReferralFilterProps } from 'views/Profile/Transactions/types'
import { ReferralSortBy } from 'views/Referral/types'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging, PrepareReferralClaim, PrepareReferralClaimRes } from './types'

class ReferralService extends HunnyPlayRequest {
  public getMeta(): HunnyRequest<BaseResponse<ReferralMetaData>> {
    const result = this._post(APIEndpointEnum.ReferralMeta)
    return result
  }

  public getOverview(): HunnyRequest<BaseResponse<ReferralOverviewResponse>> {
    const result = this._post(APIEndpointEnum.ReferralOverview, null, { authRequest: true })
    return result
  }

  public getReferralBonus(): HunnyRequest<BaseResponse<ReferralBonus>> {
    const result = this._post(APIEndpointEnum.ReferralBonus, null, { authRequest: true })
    return result
  }

  public getCampaignDefault(): HunnyRequest<BaseResponse<ReferralCampaign>> {
    const result = this._post(APIEndpointEnum.ReferralDefaultCampaign, null, { authRequest: true })
    return result
  }

  public getCampaigns(
    offset = 0,
    limit = 10,
    filters: { sortBy: ReferralSortBy },
  ): HunnyRequest<BaseResponse<Paging<ReferralCampaign>>> {
    const result = this._post(
      APIEndpointEnum.ReferralListCampaign,
      {
        paging: {
          offset,
          limit,
        },
        filter: {
          list_type: filters?.sortBy,
        },
      },
      { authRequest: true },
    )

    return result
  }

  public getCommission(): HunnyRequest<BaseResponse<Paging<ReferralBalanceTokenAmount>>> {
    const result = this._post(APIEndpointEnum.ReferralAvailableBalanceClaim, null, { authRequest: true })

    return result
  }

  public getTotalEarned(): HunnyRequest<BaseResponse<Paging<ReferralBalanceTokenAmount>>> {
    const result = this._post(APIEndpointEnum.ReferralTotalEarned, null, { authRequest: true })

    return result
  }
  public getBonusClaimedList(
    offset = 0,
    limit = 10,
    filters: ReferralBonusFilterProps,
  ): HunnyRequest<BaseResponse<Paging<ReferralTxnBonusClaim>>> {
    const result = this._post(
      APIEndpointEnum.ReferralBonusClaimList,
      {
        paging: {
          limit,
          offset,
        },
        filter: {
          ...formatFitlerTransaction(filters),
          type: filters?.referralType,
        },
      },
      { authRequest: true },
    )

    return result
  }

  public prepareAvailableBalanceClaim(
    payload: PrepareReferralClaim,
  ): HunnyRequest<BaseResponse<PrepareReferralClaimRes>> {
    const result = this._post(
      APIEndpointEnum.ReferralPrepareClaim,
      {
        currency: payload.selectedToken.code,
        network: formatToApiNetworkField(ChainIdEnum[payload.selectedToken.network]),

        items: payload.items.map((item) => ({
          currency: item.token.code,
          network: formatToApiNetworkField(ChainIdEnum[item.token.network]),
          value: item.amount,
        })),
      },
      {
        excludeErrors: ['error_blockchain_network'],
        authRequest: true,
      },
    )

    return result
  }

  public referralLiveReward(): HunnyRequest<BaseResponse<ReferralLiveReward[]>> {
    return this._post(APIEndpointEnum.ReferralLiveReward)
  }

  public claimBalance(payload: ReferralBalanceTokenAmount[]): HunnyRequest<BaseResponse<any>> {
    const result = this._post(
      APIEndpointEnum.ReferralClaimBalance,
      {
        items: payload.map((balance) => ({
          currency: balance.token.code,
          network: formatToApiNetworkField(ChainIdEnum[balance.token.network]),
          value: balance.amount,
          converted_value: balance.convertedValue,
        })),
      },
      { authRequest: true },
    )

    return result
  }

  public claimReferralBonus(): HunnyRequest<BaseResponse<any>> {
    const result = this._post(APIEndpointEnum.ReferralBonusClaim, null, { authRequest: true })
    return result
  }

  public create({
    name,
    commission,
    shareCommission,
    isDefault,
  }: {
    name: string
    commission: number
    shareCommission: number
    isDefault: boolean
  }): HunnyRequest<BaseResponse<ReferralCampaign>> {
    const result = this._post(
      APIEndpointEnum.ReferralCreateCampaign,
      {
        name,
        referrer_percentage: commission,
        referee_percentage: shareCommission,
        default: isDefault,
      },
      { authRequest: true },
    )
    return result
  }

  public delete(id: number): HunnyRequest<BaseResponse<null>> {
    const result = this._post(
      APIEndpointEnum.ReferralUpdateCampaign,
      {
        id,
        status: -1,
      },
      { authRequest: true },
    )

    return result
  }

  public update(id: number, name: string, isDefault?: boolean): HunnyRequest<BaseResponse<null>> {
    const result = this._post(
      APIEndpointEnum.ReferralUpdateCampaign,
      { id, name, default: isDefault },
      { authRequest: true },
    )
    return result
  }

  public getReferees(
    offset: number,
    limit: number,
    filters?: ReferralFilterProps,
  ): HunnyRequest<BaseResponse<Paging<Referee>>> {
    const result = this._post(
      APIEndpointEnum.RefereeList,
      {
        paging: {
          limit,
          offset,
        },
        filter: {
          ...formatFitlerTransaction(filters),
          list_type: filters?.sortBy,
        },
      },
      { authRequest: true },
    )

    return result
  }
}

const instance = new ReferralService()
export default instance
