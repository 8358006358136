import { CollectionDetail } from 'config/types/collection'
import { Game } from 'config/types/game'
import { BaseResponse, Paging } from 'services/types'
import { GameMaintenanceStatusResponse } from './Game'

export const CollectionsMapper = (rawResponse: string): BaseResponse<Paging<CollectionDetail>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: response.data.items.map((item: any) => parseCollection(item)),
    },
  }
}

export const CollectionsGameDetailMapper = (rawResponse: string): BaseResponse<Paging<Game>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: response.data.games.map((item: any) => parseGame(item)),
    },
  }
}
export const GameWagerMultiplierMapper = (rawResponse: string): BaseResponse<number> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: Number(response.data?.wager_multiplier) || null,
  }
}

export const RecommendedGamesMapper = (rawResponse: string): BaseResponse<Game[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data.items?.map(
      (item) =>
        ({
          code: item.code,
          name: item.name,
          slug: item.slug,
          favoriteCount: item.favorite_count,
          providerCode: item.provider_code,
          thumbnail: item.banner_url,
        } as Game),
    ),
  }
}

const parseGame = (game: any): Game => {
  return {
    code: game.code,
    name: game.name,
    providerCode: game?.provider_code || 'Unknow',
    slug: game.slug,
    thumbnail: game.banner_big_url || game.banner_url,
    favoriteCount: game.favorite_count || 0,
    type: game.type || 1,
    isMaintenance: game.is_maintenance === GameMaintenanceStatusResponse,
  }
}

const parseCollection = (collection: any): CollectionDetail => {
  return {
    id: collection.id,
    code: collection.code,
    name: collection.name,
    priority: collection.priority,
  }
}
