import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'config/constants/number'
import { useMemo } from 'react'
import { useAllTokens, useTokenUsdPrices } from 'state/app/hooks'
import { useTokenAppBalances } from 'state/profile/hooks'

export const useUserTotalBalance = (): BigNumber => {
  const prices = useTokenUsdPrices()
  const tokens = useAllTokens()

  const tokenAmounts = useTokenAppBalances(tokens)

  return useMemo(() => {
    return tokenAmounts.reduce((state, tokenAmount) => {
      const tokenInUsd = tokenAmount.amount.times(prices[tokenAmount.token.code])
      return state.plus(tokenInUsd.gt(0) ? tokenInUsd : BIG_ZERO)
    }, BIG_ZERO)
  }, [tokenAmounts, prices])
}
