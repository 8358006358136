import Box from 'components/Box/Box'
import Button from 'components/Button'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import FormInputPassword from 'components/FormControl/FormInputPassword'
import { InputContainer, InputLabel } from 'components/Input/styled'
import CircleLoader from 'components/Loader/CircleLoader'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import FormValidator, { commomErrorMessage } from 'config/constants/formValidator'
import { ValidationError } from 'config/types/validator'
import useCallbackValueOnDestroy from 'hooks/useCallbackValueOnDestroy'
import useDebounceCallback from 'hooks/useDebounceCallback'
import useForm, { FieldState } from 'hooks/useForm'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useModal from 'hooks/useModal'
import useNewUserJourney from 'hooks/useNewUserJourney'
import useQueryParam from 'hooks/useQueryParam'
import { useReferralCode } from 'hooks/useReferralCode'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import useToggle from 'hooks/useToggle'
import { RowBetween } from 'layout/Components/Row'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import AuthenticationService from 'services/AuthenticationService'
import { PasswordNonceResponse, RegisteredEmailPayload, VerificationResponse } from 'services/types'
import { useAppDispatch } from 'state'
import { useClientLogin } from 'state/auth/hooks'
import { useUserOnboardingStatus } from 'state/profile/hooks'
import { updateDeviceUid } from 'state/session/actions'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { constructEncryptedPassword } from 'utils/auth'
import { getID } from 'utils/fingerprint'
import { forkjoinRequest, transformHunnyRequest } from 'utils/requestHelper'
import { getHUSDBonus } from 'utils/signupBonus'
import { AuthModalPageEnums } from 'views/Authentication/types'
import { useBuildTeleAppData } from 'hooks/useTelegram'
import BonusCountdown from '../BonusCountdown'
import ReferralCode from '../ReferralCode'
import CheckCondition from './CheckCondition'
import EmailVerificationModal from './modals/EmailVerificationModal'

const signupFormErrorMessages = {
  email: {
    [ValidationError.Required]: 'Please enter your email',
    [ValidationError.Email]: commomErrorMessage.Email,
  },
  password: {
    [ValidationError.Required]: 'Please enter your password',
  },
  isConfirmedTerms: {
    [ValidationError.NotChecked]: 'Please read and agree to the terms of service.',
  },
}

export type TraditionalFormProps = {
  saveEmail: (email: string) => void
  initialEmail: string
  setPage: (page: AuthModalPageEnums) => void
}

const buildRegisterEmailExistedFn = (value: string) => {
  const request = AuthenticationService.getTraditionalSignUpPrepare({ username: value, referralCode: null })
  return transformHunnyRequest(request, (res) => {
    return !!(res && !res.data)
  })
}

const TraditionalSignUpForm: React.FC<TraditionalFormProps> = ({ saveEmail, initialEmail }) => {
  const { t } = useTranslation()
  const { execute } = useRequest()
  const debounceCallback = useDebounceCallback()
  const [handlePresentEmailVerificationModal] = useModal(EmailVerificationModal)
  const dispatch = useAppDispatch()
  const refCode = useReferralCode()
  const login = useClientLogin()
  const bonus = getHUSDBonus()

  const { removeParam } = useQueryParam()
  const startJourney = useNewUserJourney()
  const { update } = useUserOnboardingStatus()
  const { webAppData } = useBuildTeleAppData()

  const [isExpandPromoCode, toggleExpandPromoCode] = useToggle(false)
  const [submiting, setSubmiting] = useState(false)
  const router = useRouter()
  const { bonus: bonusCodeUrl } = router.query

  const { states, controls, validateAll, isValid } = useForm({
    email: {
      validators: [FormValidator.required, FormValidator.email, FormValidator.uniqueEmail(buildRegisterEmailExistedFn)],
      value: '',
    },
    password: {
      validators: [
        FormValidator.required,
        FormValidator.inputLength({ min: 8 }),
        FormValidator.requiredNumber,
        FormValidator.requiredUppercase,
        FormValidator.blockCharaters([' ']),
      ],
      value: '',
      validateOnChange: true,
    },
    promoCode: {
      value: bonusCodeUrl || '',
    },
    isConfirmedTerms: {
      validators: [FormValidator.checked],
      value: true,
      validateOnChange: true,
    },
  })

  useIsomorphicEffect(() => {
    debounceCallback(() => {
      controls.email.onValueChanged(initialEmail)
    }, 200)
  }, [initialEmail])

  useCallbackValueOnDestroy(states.email.value, saveEmail)

  const formatEmailErrorMessage = (errors: ValidationError[]) => {
    // TODO set key translation
    //  Ref: https://stackoverflow.com/questions/61268001/react-i18n-trans-component-with-translations-that-contain-html-tags-not-working

    if (errors[0] === ValidationError.EmailDuplicate) {
      return (
        <>
          <Trans>This email is already in use. Please enter a different email.</Trans>
        </>
      )
    }

    return t(signupFormErrorMessages.email[errors[0]])
  }

  const buidHandleVerifiedValidFn = (passwordNonce: PasswordNonceResponse) => {
    return async (verification: VerificationResponse, code: string) => {
      const deviceUid = await getID()

      const params: RegisteredEmailPayload = {
        username: states.email.value,
        password: constructEncryptedPassword(states.password.value, passwordNonce),
        deviceUid,
        nonceId: passwordNonce.id,

        verifications: [
          {
            type: 'email',
            data: {
              ...verification,
              otp: code,
            },
          },
        ],
        webAppData,
      }

      if (refCode.type === 'affilka') {
        params.affilkaCode = refCode.refCode
      } else if (refCode.type === 'hunnyplay') {
        params.referralCode = refCode.refCode
      }

      if (bonus) params.husdBonusAmount = bonus?.amount || 0
      const result = await AuthenticationService.registerEmail(params)

      if (result?.data?.uid) {
        update(true)
        dispatch(updateDeviceUid({ deviceUid }))
        login(
          {
            authBy: result.data.authBy,
            uid: result.data.uid,
            username: states.email.value,
            wallet: null,
            email: states.email.value,
          },
          deviceUid,
          { event: 'register' },
        )

        startJourney({
          husdBonusAmount: result?.data?.husdBonusAmount,
          bonusCode: bonusCodeUrl?.toString() || states.promoCode.value,
        })
        removeParam('bonus')
      }

      return result
    }
  }

  const handleRegister = async () => {
    setSubmiting(true)
    const isValid = await validateAll()
    if (!isValid) {
      setSubmiting(false)
      return
    }

    const [verification, passwordNonce] = await forkjoinRequest([
      execute(AuthenticationService.verifyRegisterEmail(states.email.value)),
      execute(AuthenticationService.getTraditionalSignUpPrepare({ username: states.email.value, referralCode: null })),
    ])
    if (!verification?.data || !passwordNonce.data) {
      setSubmiting(false)
      // Show error
      return
    }
    handlePresentEmailVerificationModal(
      {
        email: states.email.value,
        initialVerifiedResult: verification.data,
        verifiedHunnyRequestFn: () =>
          transformHunnyRequest(AuthenticationService.verifyRegisterEmail(states.email.value), (res) => res?.data),
        onSubmit: buidHandleVerifiedValidFn(passwordNonce.data),
      },
      true,
    )

    setSubmiting(false)
  }

  return (
    <OpenEffect openType="slideBottomToTop" duration={0.5}>
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          handleRegister()
          return
        }}
      >
        <StyledFormControl state={states.email} label={t('Email')} formatErrorMessage={formatEmailErrorMessage}>
          <FormInput
            tabIndex={1}
            control={controls.email}
            placeholder={t('Email')}
            type="email"
            name="email"
            icon={<Icons.EmailIcon />}
            adornment={
              states.email.isValidating ? (
                <Box className="hide-when-focus" height="20px">
                  <CircleLoader />
                </Box>
              ) : null
            }
          />
        </StyledFormControl>

        <StyledFormControl
          mt="12px"
          state={states.password}
          label={t('Password')}
          formatErrorMessage={(errors) => t(signupFormErrorMessages.password[errors[0]])}
          instructionConfig={[
            { message: t('Use at least 8 characters'), validationError: ValidationError.TooShort },
            { message: t('Use 1 or more number'), validationError: ValidationError.RequiredNumber },
            { message: t('Use upper case characters'), validationError: ValidationError.RequiredUppercase },
            { message: t('Password does not allow space'), validationError: ValidationError.IncorrectType },
          ]}
        >
          <FormInputPassword
            control={controls.password}
            placeholder={t('Password')}
            icon={<Icons.PasswordIcon />}
            tabIndex={1}
          />
        </StyledFormControl>

        <StyledFormControl mt="12px" state={states.promoCode} formatErrorMessage={() => ''}>
          <RowBetween mb="2px" onClick={toggleExpandPromoCode}>
            <InputLabel>{t('Enter Promo Code (Optional)')}</InputLabel>
            <StyledDropdownIcon $isExpanded={isExpandPromoCode} width="20px" height="20px" />
          </RowBetween>
          {isExpandPromoCode && (
            <FormInput
              tabIndex={1}
              name="promo-code"
              control={controls.promoCode}
              placeholder={t('Promo Code')}
              icon={
                <Box style={{ transform: 'scale(0.85)' }}>
                  <Icons.TicketIcon size={24} />
                </Box>
              }
            />
          )}
        </StyledFormControl>

        <Box mt="24px">
          <ReferralCode mb="12px" />
          <CheckCondition {...controls.isConfirmedTerms} />
        </Box>

        <Button type="submit" id="signup-submit-button" disabled={!isValid || submiting} width="100%" mt="24px">
          {submiting && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'} tabIndex={1}>
            <Trans>Create Account</Trans>
          </Text>
        </Button>
      </Box>

      <BonusCountdown onExpired={() => {}} display={['block', '', '', 'none']} mt="36px" />
    </OpenEffect>
  )
}

const StyledFormControl = styled(FormControl)<{ state: FieldState }>`
  ${({ state, theme }) =>
    state.errors.length === 0 && state.isDirty && !state.isValidating
      ? `
     ${InputContainer} {
        background-color: transparent !important;
        border-color: ${theme.colors.success} !important;
      }
    `
      : ''}
  cursor: pointer;
`

const StyledDropdownIcon = styled(Icons.ArrowDownIcon)<{ $isExpanded }>`
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: ${({ theme: { transitions } }) => transitions.fast};

  path {
    fill: ${({ theme: { colors } }) => colors.textAlt} !important;
  }
`

export default TraditionalSignUpForm
