import Box from 'components/Box/Box'
import Button from 'components/Button'
import { ButtonProps } from 'components/Button/types'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import { USER_LOCKED_WITHDRAW_ERROR_CODE } from 'config/constants/server'
import { Token } from 'config/types'
import { FiatPaymentTransaction } from 'config/types/transaction'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { PrepareTraditionalWithdrawFiatPayload } from 'services/types'
import { useAppSelector } from 'state'
import { useUserSettings } from 'state/profile/hooks'
import { transformHunnyRequest } from 'utils/requestHelper'
import EmailVerificationModal from 'views/Authentication/components/Traditional/modals/EmailVerificationModal'
import WithdrawLockingAnnounceModal from '../WithdrawLockingAnnounceModal'

export interface TraditionalWithdrawFiatAction {
  payload: {
    bankAccountNumber: string
    bankAccountName: string
    bankCode: string
    selectedCurrency: Token
    fee: string
    value: string
    accountMobileNumber?: string
  }
  validateAll: () => Promise<boolean>
  onSuccess: (fiatTransaction: FiatPaymentTransaction) => void
}

const TraditionalWithdrawButton: React.FC<TraditionalWithdrawFiatAction & ButtonProps> = ({
  validateAll,
  onSuccess,
  disabled,
  payload: { fee, value, selectedCurrency, bankAccountName, bankAccountNumber, bankCode, accountMobileNumber },
  ...props
}) => {
  const { email } = useAppSelector((state) => state.auth)
  const { execute } = useRequest()

  const [handlePresentEmailVerificationModal] = useModal(EmailVerificationModal)
  const [onPresentWithdrawLockModal] = useModal(WithdrawLockingAnnounceModal, 'withdraw_lock_modal')
  const {
    userSettings: { isEnable2FAForWithdraw },
  } = useUserSettings()

  const [submiting, setSubmiting] = useState<boolean>(false)

  const handleWithdraw = async () => {
    setSubmiting(true)
    const isValid = await validateAll()
    if (!isValid) {
      setSubmiting(false)
      return
    }

    const parsedWithdrawPayload: PrepareTraditionalWithdrawFiatPayload = {
      payload: {
        selectedCurrency,
        fee,
        value,
        bankAccountName,
        bankAccountNumber,
        bankCode,
        accountMobileNumber,
      },
    }

    const verification = await execute(PaymentService.prepareTraditionalWithdrawFiat(parsedWithdrawPayload))

    if (verification.code === USER_LOCKED_WITHDRAW_ERROR_CODE) {
      setSubmiting(false)
      return onPresentWithdrawLockModal({ countdownTime: verification.data.withdrawLockTime })
    }

    if (!verification?.data) return setSubmiting(false)
    handlePresentEmailVerificationModal(
      {
        email,
        isUse2FA: isEnable2FAForWithdraw,
        initialVerifiedResult: verification.data,
        verifiedHunnyRequestFn: () =>
          transformHunnyRequest(
            PaymentService.prepareTraditionalWithdrawFiat(parsedWithdrawPayload),
            (res) => res?.data,
          ),
        async onSubmit(verification, otp, totpAuthCode) {
          const withdrawResponse = await PaymentService.traditionalWithdrawFiat({
            ...parsedWithdrawPayload,
            otp,
            verificationCode: verification.token,
            totpAuthCode,
          })

          if (withdrawResponse?.data) {
            onSuccess(withdrawResponse.data)
          }

          return withdrawResponse
        },
      },
      true,
    )

    setSubmiting(false)
  }

  return (
    <Button disabled={submiting || disabled} onClick={handleWithdraw} {...props}>
      {submiting && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}

      <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
        <Trans>Next</Trans>
      </Text>
    </Button>
  )
}

export default TraditionalWithdrawButton
