import { ChainIdEnum } from 'config/constants/network'
import { QueryDepositStatusEnums } from 'config/constants/transactions'
import { SerializeTokenAmount } from '.'
import { DepositFiatPaymentTypeEnums, TipDetails, WithdrawFiatPaymentTypeEnums } from './payment'

export enum TransactionStatusEnum {
  Failed = -1,
  Pending = 1,
  InBatch = 2,
  Reviewing = 3,
  Succeeded = 10,
  PendingAPI = 4, // this is include  Pending InBatch Reviewing, if user select pending
  Expired = -2,
}

export const PendingTransaction = [
  TransactionStatusEnum.Pending,
  TransactionStatusEnum.InBatch,
  TransactionStatusEnum.Reviewing,
]

export enum TransactionTypeEnum {
  Deposit,
  Withdraw,
  Reward,
  Bonus,
  Swap,
  Bet,
  Cashback,
  LuckSpin,
  Husd,
  RankingBonus,
  Tip,
  DailyCheckin,
}

export enum TipTransactionFilterTypeEnums {
  Send = 'send',
  Receive = 'receive',
}

export class RewardTransaction {
  id: number
  note: string
  subject: string
  value: string
  createTime: number
  currency: string
  network: ChainIdEnum
}

export class BonusTransaction {
  id: number
  subject: string
  value: string
  createTime: number
  currency: string
  network: ChainIdEnum
  level: number
}

export class SwapTransaction {
  id: number
  createTime: number
  fromCurrency: string
  fromNetwork: string
  fromValue: string
  toCurrency: string
  toNetwork: string
  toValue: string
}

export interface TipTransaction extends TipDetails {}

export class Transaction {
  id: number
  code?: string
  status: TransactionStatusEnum
  txnHash?: string
  value: string
  createTime?: number
  currency: string
  network: ChainIdEnum
  type: TransactionTypeEnum
  fee: string
  isFtd?: boolean
  fiat?: {
    bankName: string
  }
  instruction?: string
}

export class QueryDepositInfo {
  depositInfo: {
    currency: string
    network: ChainIdEnum
    value: string
    status: TransactionStatusEnum
  }
  status: QueryDepositStatusEnums
}

export interface FiatPaymentTransaction {
  id?: string
  amount: SerializeTokenAmount
  createdAt: number
  txnHash: string
  name?: string
  code?: string
}

export interface DepositFiatTransaction extends FiatPaymentTransaction {
  method: DepositFiatPaymentTypeEnums
}

export interface WithdrawFiatTransaction extends FiatPaymentTransaction {
  method: WithdrawFiatPaymentTypeEnums
}
