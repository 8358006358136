import { APIEndpointEnum } from 'config/constants/server'
import { Notification, NotificationStatusEnums } from 'config/types/notification'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging } from './types'

class NotificationService extends HunnyPlayRequest {
  public getSystemNotifications(
    offset: number,
    limit: number,
    filterEnums: NotificationStatusEnums,
  ): HunnyRequest<BaseResponse<Paging<Notification>>> {
    const result = this._post(APIEndpointEnum.GetSystemNotifications, {
      paging: {
        limit,
        offset,
      },
      filter:
        filterEnums !== NotificationStatusEnums.ALL ? { is_read: filterEnums === NotificationStatusEnums.READ } : {},
    })

    return result
  }

  public getPrivateNotifications(
    offset: number,
    limit: number,
    filterEnums: NotificationStatusEnums,
  ): HunnyRequest<BaseResponse<Paging<Notification>>> {
    const result = this._post(
      APIEndpointEnum.GetPrivateNotifications,
      {
        paging: {
          offset,
          limit,
        },
        filter:
          filterEnums !== NotificationStatusEnums.ALL ? { is_read: filterEnums === NotificationStatusEnums.READ } : {},
      },
      { authRequest: true },
    )

    return result
  }

  public markAsReadPrivateNotification(notificationIds: string[]) {
    const result = this._request(
      APIEndpointEnum.MarkAsReadPrivateNotification,
      {
        ids: notificationIds,
      },
      { authRequest: true },
    )

    return result
  }

  public markAllAsReadPrivateNotification() {
    const result = this._request(APIEndpointEnum.MarkAllAsReadPrivateNotification, null, { authRequest: true })

    return result
  }

  public markAsReadSystemNotification(notificationIds: string[]) {
    const result = this._request(
      APIEndpointEnum.MarkAsReadSystemNotification,
      {
        ids: notificationIds,
      },
      { authRequest: true },
    )

    return result
  }

  public markAllAsReadSystemNotification() {
    const result = this._request(APIEndpointEnum.MarkAllAsReadSystemNotification, null, { authRequest: true })

    return result
  }
}

const instance = new NotificationService()

export default instance
