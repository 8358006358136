import useModal, { useCloseAllModal } from 'hooks/useModal'
import { useBuildTeleAppData } from 'hooks/useTelegram'
import { useCallback } from 'react'
import AuthenticationService from 'services/AuthenticationService'
import { LoginResponse } from 'services/types'
import { useClientLogin } from 'state/auth/hooks'
import { constructEncryptedPassword } from 'utils/auth'
import { getID } from 'utils/fingerprint'
import ProvideTotpAuthCodeModal from 'views/Profile/Settings/components/SettingSecurity/2FA/ProvideTotpAuthCodeModal'

export const useSignInWithEmail = () => {
  const login = useClientLogin()
  const [onPresentProvideTotpCode] = useModal(ProvideTotpAuthCodeModal)
  const closeAllModal = useCloseAllModal()
  const { webAppData } = useBuildTeleAppData()

  const handleLoginSuccessCallback = useCallback((data: LoginResponse & { deviceUid: string; username: string }) => {
    login(
      {
        authBy: data.authBy,
        uid: data.uid,
        username: data.username,
        wallet: null,
        email: data.username,
      },
      data.deviceUid,
    )
  }, [])

  return useCallback(
    async (password: string, username: string) => {
      const prepareResponse = await AuthenticationService.getTraditionalSignInPrepare(username)
      if (!prepareResponse?.data) return { code: 'error' }
      const deviceUid = await getID()
      const response = await AuthenticationService.signInByEmail({
        deviceUid,
        username,
        password: constructEncryptedPassword(password, prepareResponse.data),
        nonceId: prepareResponse.data.id,
        webAppData,
      })

      if (response.code === 'success') {
        if (response.data?.prepare2FAData) {
          onPresentProvideTotpCode({
            onTotpAuthCodeConfirmed: async (code) => {
              const verify2FAResponse = await AuthenticationService.verifySignInByEmailBy2FA({
                deviceUid,
                token: response.data.prepare2FAData.token,
                username,
                totpAuthCode: code,
              })
              if (verify2FAResponse.code === 'success') {
                handleLoginSuccessCallback({ ...verify2FAResponse.data, deviceUid, username })
                closeAllModal()
              }
              return verify2FAResponse
            },
          })
        } else {
          handleLoginSuccessCallback({ ...response.data, deviceUid, username })
        }
      }

      return response
    },
    [webAppData],
  )
}
