import { DEFAULT_TIME } from 'config/constants'
import { useEffect, useRef } from 'react'
import { useAppSelector } from 'state'
import { generateTimeDisplay } from 'utils/dateHelper'

export const useCountDown = (
  to: number,
  formatDisplay: (seconds: number, minutes?: number, hours?: number, days?: number) => string,
  callback?: () => void,
  displayFullHourFormat?: boolean,
) => {
  const serverTimeMargin = useAppSelector((state) => state.app.serverTimeMargin)
  const elementRef = useRef(null)
  useEffect(() => {
    if (!to) return

    const updateCountdown = () => {
      if (!elementRef.current || serverTimeMargin === undefined || serverTimeMargin === null) return
      const result = generateTimeDisplay(to, serverTimeMargin, displayFullHourFormat)
      elementRef.current.innerHTML = formatDisplay(result.seconds, result.minutes, result.hours, result.days)

      if (result === DEFAULT_TIME) {
        clearInterval(interval)

        if (callback) {
          callback()
          return
        }
      }
    }

    updateCountdown()
    const interval = setInterval(updateCountdown, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [to, serverTimeMargin])

  return elementRef
}
