/* eslint-disable no-useless-computed-key */
import { APIEndpointEnum } from 'config/constants/server'
import { INHOUSE_GAME_LAUNCH_MAPPER_KEY } from 'views/InhouseGame/Mine/config'
import {
  FindUserDataMapper,
  LoginResponseMapper,
  NewPasswordNonceMapper,
  PasswordNonceResponseMapper,
  PreparWeb3UserAddEmailMapper,
  PrepareSignMessageMapper,
  PrepareTraditionalEnable2FAMapper,
  PrepareWalletEnable2FAMapper,
  PrepareWalletEnable2FAResendEmailMapper,
  RegisterMapper,
  ResetPasswordVerificationResponseMapper,
  UserDataMapper,
  VerificationResponseMapper,
} from './Authenication'
import { PostDetailsMapper, PostsMapper, TagDetailsMapper, TagsMapper } from './Blog'
import {
  AirdropListMapper,
  ClaimAirdropMapper,
  ClaimBonusMapper,
  ClaimableBalanceMapper,
  DailyCheckinHistoryMapper,
  DailyCheckinMapper,
  HUSDClaimedAmountMapper,
  HUSDClaimedHistoryMapper,
  LockBalanceMapper,
  ReceivedAirdropSummaryMapper,
  SwapBonusMapper,
  UnlockFormulaMapper,
} from './Bonus'
import { CashbackTotalEarnedMapper, CashbackTransactionMapper } from './Cashback'
import {
  CollectionsGameDetailMapper,
  CollectionsMapper,
  GameWagerMultiplierMapper,
  RecommendedGamesMapper,
} from './Collection'
import {
  BetDetailMapper,
  FavoriteGamesMapper,
  GameDescriptionMapper,
  GameDetailMapper,
  GameDetailTopWinListMapper,
  GameRatingInfoMapper,
  GameSessionMapper,
  GameSessonDemoMapper,
  GamesMapper,
  LiveBetMapper,
  CreditBonusRestrictedGamesMapper,
  ScreenTagMapper,
  UserGameRatingDataMapper,
} from './Game'
import { BannerMapper, HomeCategoryMapper } from './Home'
import {
  GetBetMapper,
  GetSeedMapper,
  InhouseFinishBetMapper,
  InhouseGameLaunchMapper,
  InhouseGamePlayMapper,
  InhouseGameSubmitMapper,
} from './InhouseGameMappers'
import {
  SpinFreePlayTimeMapper,
  SpinMyPrizeTransactionMapper,
  SpinPlayMapper,
  SpinPrizeListMapper,
  SpinPrizeTransactionMapper,
} from './LuckySpin'
import { MetaDataMapper, ProviderListMapper, ProviderMapper, TokenInUsdPriceMapper } from './Meta'
import { ClientCountryMapper, MetaTagMapper } from './MetaTag'
import { PrivateNotificationMapper, SystemNotificationMapper } from './Notification'
import {
  BalanceMapper,
  BetTransactionsMapper,
  BonusTransactionMapper,
  DepositFiatBankMethodListMapper,
  DepositTransactionMapper,
  DepositTransactionsMapper,
  FiatDepositMapper,
  GetDepositInfoByHashMapper,
  PrepareTraditionalTipMapper,
  PrepareTraditionalWithdrawMapper,
  PrepareWalletTipMapper,
  PrepareWalletWithdrawMapper,
  QueryDepositByHashMapper,
  RewardTransactionsMapper,
  SendTipMapper,
  SwapTransactionMapper,
  TipDetailsMapper,
  TipInfoMapper,
  TipTransactionMapper,
  TraditionalDepositMapper,
  WithdrawFiatBankMethodListMapper,
  WithdrawFiatMapper,
  WithdrawInfoMapper,
  WithdrawMapper,
  WithdrawalTransactionMapper,
  WithdrawalTransactionsMapper,
} from './Payment'
import {
  AvatarMapper,
  UserDisplayNameMapper,
  UserFavoriteGameCodesMapper,
  UserGettingStartedTaskMapper,
  UserProfileMapper,
  UserProfileStatisticBonusMapper,
  UserProfileStatisticGameInfoesMapper,
  UserTierMapper,
  UserTipStatisticsMapper,
  UserTotalWagerMapper,
} from './ProfileMapper'
import { DepositBonusMapper, ListPromoMapper, PromotionDetailsMapper } from './Promotions'
import {
  RefereeListMapper,
  ReferralBalanceEarnedMapper,
  ReferralBalanceMapper,
  ReferralBonusClaimMapper,
  ReferralBonusMapper,
  ReferralCampaignMapper,
  ReferralListCampaignMapper,
  ReferralLiveRewardMapper,
  ReferralMetaMapper,
  ReferralOverviewMapper,
  ReferralPrepareClaimMapper,
} from './Referral'
import { CreateBetbySessionMapper, SportAuthTokenMapper } from './Sport'
import {
  StakingBalanceMapper,
  StakingMyPoolStatsMapper,
  StakingPoolStatsMapper,
  StakingTransactionMapper,
} from './Staking'
import { TopWinMapper } from './TopWin'
import {
  FreeBonusesCampaignMapper,
  JourneyBonusesMapper,
  PrepareCancelBonusMapper,
  PublicBonusDetailsMapper,
  UserBonusApplyMapper,
  UserBonusDetailsMapper,
  UserBonusMapper,
  UserBonusRedeemMapper,
  UserStatsWagerAdventureRewardMapper,
  UserWagerAdventureRewardMapper,
  UserWageringBonusGameCodesMapper,
  WagerAdventureMapper,
} from './Voucher'

import { FiatCurrencyConversionRateInUSDMapper } from './Fiat'
import { CheckSurveyMapper } from './survey'

class Mapper {
  private _mapper = {
    [APIEndpointEnum.DisplayName]: UserDisplayNameMapper,
    [APIEndpointEnum.Meta]: MetaDataMapper,
    [APIEndpointEnum.ClientCountry]: ClientCountryMapper,
    [APIEndpointEnum.User]: UserDataMapper,
    [APIEndpointEnum.FindUserData]: FindUserDataMapper,
    [APIEndpointEnum.TokenUsdPrices]: TokenInUsdPriceMapper,
    [APIEndpointEnum.DepositBonus]: DepositBonusMapper,
    [APIEndpointEnum.WalletLoginPrepare]: PrepareSignMessageMapper,
    [APIEndpointEnum.SignInByWallet]: LoginResponseMapper,
    [APIEndpointEnum.SignInByEmail]: LoginResponseMapper,
    [APIEndpointEnum.SignInByTelegram]: LoginResponseMapper,
    [APIEndpointEnum.Refresh]: LoginResponseMapper,
    [APIEndpointEnum.SignInByEmailWith2FA]: LoginResponseMapper,
    [APIEndpointEnum.SignUpPrepare]: PasswordNonceResponseMapper,
    [APIEndpointEnum.PrepareTraditionalLogin]: PasswordNonceResponseMapper,
    [APIEndpointEnum.VerifyRegisterUser]: VerificationResponseMapper,
    [APIEndpointEnum.Register]: RegisterMapper,
    [APIEndpointEnum.VerifyResetPassword]: ResetPasswordVerificationResponseMapper,
    [APIEndpointEnum.ValidateResetPassword]: NewPasswordNonceMapper,
    [APIEndpointEnum.ProviderList]: ProviderListMapper,
    [APIEndpointEnum.Provider]: ProviderMapper,
    [APIEndpointEnum.BannerList]: BannerMapper,
    [APIEndpointEnum.HomeCategoryList]: HomeCategoryMapper,
    [APIEndpointEnum.ScreenTagList]: ScreenTagMapper,
    [APIEndpointEnum.GameDetailTopWinList]: GameDetailTopWinListMapper,
    [APIEndpointEnum.LiveBetList]: LiveBetMapper,
    [APIEndpointEnum.BetDetail]: BetDetailMapper,

    [APIEndpointEnum.PrepareTraditionalEnable2FA]: PrepareTraditionalEnable2FAMapper,
    [APIEndpointEnum.PrepareWalletEnable2FA]: PrepareWalletEnable2FAMapper,
    [APIEndpointEnum.PrepareEnable2FAResendEmail]: PrepareWalletEnable2FAResendEmailMapper,
    [APIEndpointEnum.Web3UserAddEmailPrepare]: PreparWeb3UserAddEmailMapper,

    [APIEndpointEnum.Balances]: BalanceMapper,
    [APIEndpointEnum.TraditionalDeposit]: TraditionalDepositMapper,
    [APIEndpointEnum.WithdrawInfo]: WithdrawInfoMapper,
    [APIEndpointEnum.WithdrawFiatLockBonusBalanceInfo]: WithdrawInfoMapper,
    [APIEndpointEnum.PrepareTraditionalWithdraw]: PrepareTraditionalWithdrawMapper,
    [APIEndpointEnum.PrepareTraditionalWithdrawFiat]: PrepareTraditionalWithdrawMapper,
    [APIEndpointEnum.PrepareWalletWithdraw]: PrepareWalletWithdrawMapper,
    [APIEndpointEnum.PrepareWalletWithdrawFiat]: PrepareWalletWithdrawMapper,
    [APIEndpointEnum.WalletWithdrawFiat]: WithdrawFiatMapper,
    [APIEndpointEnum.WalletWithdraw]: WithdrawMapper,
    [APIEndpointEnum.TraditionalWithdraw]: WithdrawMapper,
    [APIEndpointEnum.TraditionalWithdrawFiat]: WithdrawFiatMapper,
    [APIEndpointEnum.QueryDepositByHash]: QueryDepositByHashMapper,
    [APIEndpointEnum.DepositFiatBankMethodList]: DepositFiatBankMethodListMapper,
    [APIEndpointEnum.WithdrawFiatBankMethodList]: WithdrawFiatBankMethodListMapper,
    [APIEndpointEnum.FiatDeposit]: FiatDepositMapper,
    [APIEndpointEnum.ResumeDepositFiat]: FiatDepositMapper,

    [APIEndpointEnum.PrepareWalletTip]: PrepareWalletTipMapper,
    [APIEndpointEnum.PrepareTraditionalTip]: PrepareTraditionalTipMapper,
    [APIEndpointEnum.TipDetails]: TipDetailsMapper,
    [APIEndpointEnum.WalletSendTip]: SendTipMapper,
    [APIEndpointEnum.TraditionalSendTip]: SendTipMapper,
    [APIEndpointEnum.TipTinfo]: TipInfoMapper,

    [APIEndpointEnum.WithdrawalTransaction]: WithdrawalTransactionMapper,
    [APIEndpointEnum.WithdrawalTransactions]: WithdrawalTransactionsMapper,
    [APIEndpointEnum.DepositTransaction]: DepositTransactionMapper,
    [APIEndpointEnum.DepositTransactions]: DepositTransactionsMapper,
    [APIEndpointEnum.RewardTransactions]: RewardTransactionsMapper,
    [APIEndpointEnum.BonusTransactions]: BonusTransactionMapper,
    [APIEndpointEnum.SwapTransactions]: SwapTransactionMapper,
    [APIEndpointEnum.BetTransactions]: BetTransactionsMapper,
    [APIEndpointEnum.TipTransactions]: TipTransactionMapper,
    [APIEndpointEnum.GetDepositInfoByTxnHash]: GetDepositInfoByHashMapper,

    [APIEndpointEnum.Games]: GamesMapper,
    [APIEndpointEnum.UserFavoriteGames]: FavoriteGamesMapper,
    [APIEndpointEnum.GameDescription]: GameDescriptionMapper,
    [APIEndpointEnum.GameRatingInfo]: GameRatingInfoMapper,
    [APIEndpointEnum.UserGameRating]: UserGameRatingDataMapper,
    [APIEndpointEnum.UserRecentGames]: FavoriteGamesMapper,
    [APIEndpointEnum.BonusGameCollection]: GamesMapper,
    [APIEndpointEnum.CreateGameSession]: GameSessionMapper,
    ['CREATE_BETBY_SESSION']: CreateBetbySessionMapper,
    [APIEndpointEnum.GameDetail]: GameDetailMapper,
    [APIEndpointEnum.MerchantBetby]: SportAuthTokenMapper,
    [APIEndpointEnum.GameCollectionList]: CollectionsMapper,
    [APIEndpointEnum.GameCollectionDetail]: CollectionsGameDetailMapper,
    [APIEndpointEnum.GameWagerMultiplier]: GameWagerMultiplierMapper,
    [APIEndpointEnum.RecommendedGames]: RecommendedGamesMapper,
    [APIEndpointEnum.CreditBonusRestrictedGames]: CreditBonusRestrictedGamesMapper,

    [APIEndpointEnum.PromotionList]: ListPromoMapper,
    [APIEndpointEnum.PromotionDetail]: PromotionDetailsMapper,
    [APIEndpointEnum.ReferralOverview]: ReferralOverviewMapper,
    [APIEndpointEnum.ReferralMeta]: ReferralMetaMapper,
    [APIEndpointEnum.ReferralAvailableBalanceClaim]: ReferralBalanceMapper,
    [APIEndpointEnum.ReferralTotalEarned]: ReferralBalanceEarnedMapper,
    [APIEndpointEnum.ReferralPrepareClaim]: ReferralPrepareClaimMapper,
    [APIEndpointEnum.ReferralListCampaign]: ReferralListCampaignMapper,
    [APIEndpointEnum.ReferralCreateCampaign]: ReferralCampaignMapper,
    [APIEndpointEnum.RefereeList]: RefereeListMapper,
    [APIEndpointEnum.ReferralBonus]: ReferralBonusMapper,
    [APIEndpointEnum.ReferralBonusClaimList]: ReferralBonusClaimMapper,
    [APIEndpointEnum.ReferralLiveReward]: ReferralLiveRewardMapper,

    [APIEndpointEnum.CashbackTransaction]: CashbackTransactionMapper,
    [APIEndpointEnum.CashbackTotalEarned]: CashbackTotalEarnedMapper,

    [APIEndpointEnum.StakingPoolStats]: StakingPoolStatsMapper,
    [APIEndpointEnum.StakingMyPoolStats]: StakingMyPoolStatsMapper,
    [APIEndpointEnum.StakingAvailableClaim]: StakingBalanceMapper,
    [APIEndpointEnum.StakingTotalEarned]: StakingBalanceMapper,
    [APIEndpointEnum.StakingTransaction]: StakingTransactionMapper,

    [APIEndpointEnum.MyTier]: UserTierMapper,
    [APIEndpointEnum.UserFavoriteGameCodes]: UserFavoriteGameCodesMapper,
    [APIEndpointEnum.ProfileGettingStartedTaks]: UserGettingStartedTaskMapper,
    [APIEndpointEnum.ProfileStatisticGameInfoes]: UserProfileStatisticGameInfoesMapper,
    [APIEndpointEnum.ProfileStatisticBonuses]: UserProfileStatisticBonusMapper,
    [APIEndpointEnum.Avatar]: AvatarMapper,
    [APIEndpointEnum.UserProfile]: UserProfileMapper,
    [APIEndpointEnum.UserTipStatistics]: UserTipStatisticsMapper,
    [APIEndpointEnum.UserTotalWager]: UserTotalWagerMapper,

    [APIEndpointEnum.Posts]: PostsMapper,
    [APIEndpointEnum.Tags]: TagsMapper,
    [APIEndpointEnum.TagDetails]: TagDetailsMapper,
    [APIEndpointEnum.PostDetails]: PostDetailsMapper,
    [APIEndpointEnum.TopWinList]: TopWinMapper,
    [APIEndpointEnum.HUSDLockBalance]: LockBalanceMapper,
    [APIEndpointEnum.HUSDClaimableBalance]: ClaimableBalanceMapper,
    [APIEndpointEnum.ClaimHUSD]: ClaimBonusMapper,
    [APIEndpointEnum.UnlockFormula]: UnlockFormulaMapper,
    [APIEndpointEnum.TotalHUSDClaimed]: HUSDClaimedAmountMapper,
    [APIEndpointEnum.HUSDTxnList]: HUSDClaimedHistoryMapper,
    [APIEndpointEnum.SwapHUSD]: SwapBonusMapper,
    [APIEndpointEnum.DailyCheckin]: DailyCheckinMapper,
    [APIEndpointEnum.DailyCheckinHasSession]: DailyCheckinMapper,
    [APIEndpointEnum.DailyCheckinHistory]: DailyCheckinHistoryMapper,

    [APIEndpointEnum.SpinInternalPrizeList]: SpinPrizeListMapper,
    [APIEndpointEnum.SpinExternalPrizeList]: SpinPrizeListMapper,
    [APIEndpointEnum.SpinPlay]: SpinPlayMapper,
    [APIEndpointEnum.SpinPrizeTransactions]: SpinPrizeTransactionMapper,
    [APIEndpointEnum.SpinMyPrizeTransactions]: SpinMyPrizeTransactionMapper,

    [APIEndpointEnum.GetSystemNotifications]: SystemNotificationMapper,
    [APIEndpointEnum.GetPrivateNotifications]: PrivateNotificationMapper,
    [APIEndpointEnum.SpinFreePlayTimes]: SpinFreePlayTimeMapper,

    [APIEndpointEnum.UserBonuses]: UserBonusMapper,
    [APIEndpointEnum.BonusTransactionsHistory]: UserBonusMapper,
    [APIEndpointEnum.BonusPrepareCancel]: PrepareCancelBonusMapper,
    [APIEndpointEnum.UserBonusDetails]: UserBonusDetailsMapper,
    [APIEndpointEnum.FreeBonusesCampaign]: FreeBonusesCampaignMapper,
    [APIEndpointEnum.NextFreeBonusesCampaign]: FreeBonusesCampaignMapper,
    [APIEndpointEnum.BonusDetails]: PublicBonusDetailsMapper,
    [APIEndpointEnum.BonusRedeem]: UserBonusRedeemMapper,
    [APIEndpointEnum.FreeBonusCampaignClaim]: UserBonusRedeemMapper,
    [APIEndpointEnum.BonusApply]: UserBonusApplyMapper,
    [APIEndpointEnum.WageringBonusGameCodes]: UserWageringBonusGameCodesMapper,
    [APIEndpointEnum.ClaimAirdrop]: ClaimAirdropMapper,
    [APIEndpointEnum.AirdropList]: AirdropListMapper,
    [APIEndpointEnum.ActiveAdventure]: WagerAdventureMapper,
    [APIEndpointEnum.ExpireAdventure]: WagerAdventureMapper,
    [APIEndpointEnum.ListUserReward]: UserWagerAdventureRewardMapper,
    [APIEndpointEnum.AdventureUserStats]: UserStatsWagerAdventureRewardMapper,
    [APIEndpointEnum.ReceivedAirdropSummary]: ReceivedAirdropSummaryMapper,
    [APIEndpointEnum.JourneyBonuses]: JourneyBonusesMapper,

    [APIEndpointEnum.MetaTag]: MetaTagMapper,
    [APIEndpointEnum.CreateGameSessionDemo]: GameSessonDemoMapper,

    // Inhouse Game------------|
    [APIEndpointEnum.InhouseGameSubmitBet]: InhouseGameSubmitMapper,
    [APIEndpointEnum.MineGameOpenBlock]: InhouseGamePlayMapper,
    [APIEndpointEnum.InhouseFinishBet]: InhouseFinishBetMapper,
    [APIEndpointEnum.GetSeed]: GetSeedMapper,
    [APIEndpointEnum.UpdateSeed]: GetSeedMapper,
    [APIEndpointEnum.GetBetInfo]: GetBetMapper,
    [INHOUSE_GAME_LAUNCH_MAPPER_KEY]: InhouseGameLaunchMapper,
    // -------------------------|
    [APIEndpointEnum.FiatCurrencyConversionRateInUSD]: FiatCurrencyConversionRateInUSDMapper,

    [APIEndpointEnum.CheckSurvey]: CheckSurveyMapper,
  }

  public getMapper(url: string) {
    return (this._mapper as any)[url]
  }
}

const mapper = new Mapper()

export default mapper
