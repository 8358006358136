import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  XRP: {
    coinGeckoInfo: {
      id: 'xrp',
    },
    logo: `${path}/xrp.png`,
    name: 'XRP',
    code: 'XRP',
    network: ChainIdEnum.XRP,
    isNative: true,
    decimals: 6,
  },
}

export default tokens
