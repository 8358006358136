import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { StyledControlContainer } from 'components/FormControl/styled'
import { InputLabel, InputMessage } from 'components/Input/styled'
import Skeleton from 'components/Skeleton'
import Text from 'components/Text'
import TokenInput from 'components/TokenInput'
import FormValidator from 'config/constants/formValidator'
import { BIG_ZERO } from 'config/constants/number'
import { Token } from 'config/types'
import { WageringConditionBonus } from 'config/types/bonus/userBonus'
import { ValidationError } from 'config/types/validator'
import useDepositFee from 'hooks/useDepositFee'
import useForm from 'hooks/useForm'
import { useEffect, useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { useTokenWalletBalance } from 'state/profile/hooks'
import styled from 'styled-components'
import { FlexProps } from 'styled-system'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import DepositButton from './DepositButton'

const DepositInputErrorMessages = {
  depositValue: {
    [ValidationError.Insufficient]: 'Insufficient balance',
  },
}

interface DepositByContractProps {
  depositToken: Token
  selectedBonus: WageringConditionBonus
  handleSelectedBonus: (bonus: WageringConditionBonus) => Promise<void>
  isSelectedBonusExpired?: boolean
  isDepositing: boolean
  setIsDepositing: (isDepositing: boolean) => void
}

const DepositByContract: React.FC<DepositByContractProps & FlexProps> = ({
  depositToken,
  selectedBonus,
  isSelectedBonusExpired,
  handleSelectedBonus,
  setIsDepositing,
  isDepositing,
  ...props
}) => {
  const { isSigned } = useAuth()
  const estimatedFee = useDepositFee(depositToken)
  const wallet = useAppSelector((state) => state.auth.wallet)
  const tokenAmount = useTokenWalletBalance(depositToken, wallet?.address)

  const walletBalanceLockDisplayRef = useRef(null)
  useIsomorphicEffect(() => {
    if (isDepositing) {
      walletBalanceLockDisplayRef.current = tokenAmount
    }
  }, [isDepositing])

  const selectedTokenAmount = isDepositing ? walletBalanceLockDisplayRef.current : tokenAmount

  const { states, controls, validateAll, isValid, validate } = useForm({
    amount: {
      validators: [FormValidator.lte(0), FormValidator.max(selectedTokenAmount?.amount)],
      value: '',
    },
  })

  const balance = useMemo(
    () => (selectedTokenAmount ? selectedTokenAmount.amount : BIG_ZERO),
    [selectedTokenAmount?.amount.toString(), isDepositing],
  )

  useEffect(() => {
    if (states.amount.isDirty) {
      validate('amount')
    }
  }, [balance])

  const onStartDeposit = () => {
    setIsDepositing(true)
  }

  const onFailed = () => {
    setIsDepositing(false)
  }

  const onDepositSuccess = async () => {
    controls.amount.onValueChanged('')
    setIsDepositing(false)
  }

  return (
    <Flex mt="24px" flexDirection="column" {...props}>
      <Flex flex="1 1">
        <StyledControlContainer state={states.amount}>
          <StyledLabel>
            <InputLabel mb="0px !important">
              <Trans>Amount</Trans>
            </InputLabel>
            <InputLabel mb="0px !important">
              <Flex>
                <Trans>Wallet Balance</Trans>:
                {tokenAmount ? (
                  <>
                    {' '}
                    {getFullDisplayBalance(balance, 0, 6)} {depositToken?.name}
                  </>
                ) : (
                  <Skeleton display="inline" minWidth="50px" ml="4px" minHeight="16px !important" />
                )}
              </Flex>
            </InputLabel>
          </StyledLabel>
          <TokenInput
            disabled={isDepositing}
            tabIndex={1}
            value={states.amount.value}
            token={depositToken}
            errors={!isDepositing && states.amount.errors}
            validators={controls.amount.validators}
            onErrorChanged={controls.amount.onErrorChanged}
            onValueChanged={controls.amount.onValueChanged}
            max={tokenAmount?.amount}
            getFee={depositToken.isNative ? estimatedFee : null}
          />

          <Box minHeight="24px" mt="4px">
            <InputMessage color="error" textAlign="right" mb="4px">
              {!isDepositing && <Trans>{DepositInputErrorMessages.depositValue[states.amount.errors[0]]}</Trans>}
            </InputMessage>

            <Text color="textAlt" fontSize="12px" textAlign="right">
              <Trans>No deposit fee</Trans>
            </Text>
          </Box>
        </StyledControlContainer>
      </Flex>
      <Box width="100%" mt="24px">
        {isSigned && (
          <DepositButton
            id="deposit-button"
            width="100%"
            onStart={onStartDeposit}
            onFailed={onFailed}
            selectedToken={depositToken}
            validateAll={validateAll}
            handleSelectedBonus={handleSelectedBonus}
            onSucceed={onDepositSuccess}
            value={states.amount.value}
            disabled={!isValid || !states.amount.value || isSelectedBonusExpired}
            selectedBonus={selectedBonus}
            tabIndex={1}
          />
        )}
      </Box>
    </Flex>
  )
}

const StyledLabel = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default DepositByContract
