import BigNumber from 'bignumber.js'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'

export const useWithdrawInfo = (selectedToken: Token) => {
  const { execute } = useRequest()
  const [widthrawLockedAmount, setWidthrawLockedAmount] = useState<{
    lockAmount: BigNumber
    bonusId?: number
  }>({ lockAmount: new BigNumber(0) })

  useEffect(() => {
    if (selectedToken === HUSD_TOKEN) return

    const fetchFee = async () => {
      const response = await execute(PaymentService.getWithdrawFiatLockBonusBalanceInfo(selectedToken.code))
      if (response?.data) {
        setWidthrawLockedAmount({
          lockAmount: new BigNumber(response.data.lockBonusAmount || 0),
          bonusId: response.data.voucherId || null,
        })
      }
    }

    fetchFee()
  }, [selectedToken])

  return useMemo(() => {
    const { lockAmount, bonusId } = widthrawLockedAmount

    return {
      lockAmount,
      bonusId,
    }
  }, [widthrawLockedAmount])
}
