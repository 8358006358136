import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { useUserInfo } from 'state/auth/hooks'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

type UserPropertiesKey = 'language' | 'coin' | 'type' | 'wallet' | 'vip' | 'referral_by'
export type EventKey =
  | 'userDataLoaded'
  | 'search'
  | 'select_balance'
  | 'swap_husd'
  | 'withdraw'
  | 'deposit-success'
  | 'deposit-failure'
  | 'deposit-failure-no-hash'
  | 'deposit-reject'
  | 'deposit-switch-network-success'
  | 'deposit-switch-network-failure'
  | 'deposit-switch-network-reject'
  | 'deposit-approve-submitted'
  | 'deposit-approve-pending'
  | 'deposit-approve-success'
  | 'deposit-approve-failure'
  | 'deposit-approve-reject'
  | 'login'
  | 'register'
  | 'redeem_bonus'
  | 'finish_freespin'
  | 'play_game'
  | 'wagering_bonus'
  | 'finish_bonus'

// Initialize Firebase
const app = typeof window !== 'undefined' ? initializeApp(firebaseConfig) : null
const analytics = app ? getAnalytics(app) : null

export const useAnalytics = () => {
  const { uid } = useUserInfo()

  const recordEvent = (event: EventKey, value: any, overrideUid: number = null) => {
    let leafValue = value || {}
    let parsedValue = `[${event}]`

    while (typeof leafValue === 'object') {
      const key = Object.keys(leafValue)[0]
      leafValue = leafValue[key]
      if (key) parsedValue = `${parsedValue}[${key}]`
    }

    if (leafValue) parsedValue = `${parsedValue}[${leafValue}]`

    addEventString(parsedValue, overrideUid)
  }

  const addEventString = (event: string, overrideUid: number = null) => {
    const dataLayerInterval = setInterval(() => {
      if (window.dataLayer) {
        window.dataLayer.push({ event, userId: overrideUid || uid })
        clearInterval(dataLayerInterval)
      }
    }, 2000)

    const wonderPushInterval = setInterval(() => {
      if (window.WonderPush) {
        window?.WonderPush?.push(['trackEvent', event])
        clearInterval(wonderPushInterval)
      }
    }, 2000)

    setTimeout(() => {
      clearInterval(dataLayerInterval)
      clearInterval(wonderPushInterval)
    }, 20000)
  }

  const updateUserType = (key: UserPropertiesKey, value: string) => {
    setUserProperties(analytics, { [key]: value })
  }

  const updateUserId = (uid: string) => {
    setUserId(analytics, uid)

    window?.gtag('config', process.env.NEXT_PUBLIC_GA, {
      user_id: Number(uid) || null,
    })

    window?.dataLayer?.push({ userId: Number(uid) || null })
  }

  // For meta pixel trackin
  const addMetaEvent = (
    event: 'InitiateCheckout' | 'Purchase' | 'CompleteRegistration' | 'FirstDepositArrival',
    metaData?: any,
  ) => {
    const fbqInterval = setInterval(() => {
      if (window.fbq) {
        window.fbq(event === 'FirstDepositArrival' ? 'trackCustom' : 'track', event, metaData)
        clearInterval(fbqInterval)
      }
    }, 2000)

    setTimeout(() => {
      clearInterval(fbqInterval)
    }, 20000)
  }

  const addWonderPushProperty = (properties: any) => {
    const wonderPushInterval = setInterval(() => {
      if (window.WonderPush) {
        window?.WonderPush?.push(['putProperties', properties])
        clearInterval(wonderPushInterval)
      }
    }, 2000)

    setTimeout(() => {
      clearInterval(wonderPushInterval)
    }, 20000)
  }

  return { recordEvent, addMetaEvent, updateUserType, updateUserId, addEventString, addWonderPushProperty }
}

// Initialize Analytics and get a reference to the service
