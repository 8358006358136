import Box from 'components/Box/Box'
import { ContentBoxGradient, WrapperBoxGradient } from 'components/Box/BoxGradient'
import Flex from 'components/Box/Flex'
import { BoxProps, FlexProps } from 'components/Box/types'
import Text from 'components/Text'
import { HUNNY_BOT_TELEGRAM } from 'config/constants/server'
import { useLoginByCheckLinkedTelegram, useLoginTelegram } from 'hooks/useTelegram'
import Column from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { AuthModalPageEnums } from 'views/Authentication/types'
import TelegramLoginWidget from './TelegramLoginWidget'

const TelegramButton: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
  <WrapperBoxGradient
    style={{
      cursor: 'pointer',
    }}
    borderRadius="8px !important"
    {...props}
  >
    <ContentBoxGradient borderRadius="8px !important">
      <Flex height="38px" p="8px 16px" alignItems="center" justifyContent="center">
        <StyledIcon />
        <Text fontWeight={500} small>
          {children}
        </Text>
      </Flex>
    </ContentBoxGradient>
  </WrapperBoxGradient>
)

const SocialAuth: React.FC<
  FlexProps & {
    page: AuthModalPageEnums
    onSwitchPage: (nextPage: AuthModalPageEnums) => void
  }
> = ({ page, onSwitchPage, ...props }) => {
  const { t } = useTranslation()
  const login = useLoginTelegram()
  const loginCheckLinked = useLoginByCheckLinkedTelegram()

  const isSignUp = page === AuthModalPageEnums.SIGN_UP

  return (
    <Column maxWidth="fit-content" mx="auto" {...props}>
      <RowCenter mb="12px">
        <Box width="55px" height="1px" background={theme.colors.textSubtle} />
        <Text fontSize="12px" color="textTertiary" mx="10px">
          {t('Or')}
        </Text>
        <Box width="55px" height="1px" background={theme.colors.textSubtle} />
      </RowCenter>

      <TelegramLoginWidget
        onLogin={(webApp, payload) => {
          if (isSignUp) {
            login(webApp, payload)
          } else {
            loginCheckLinked(webApp, payload, () => {
              onSwitchPage(AuthModalPageEnums.SIGN_UP)
            })
          }
        }}
        bot={HUNNY_BOT_TELEGRAM}
      >
        <TelegramButton>
          {isSignUp ? <Trans>Sign up with Telegram</Trans> : <Trans>Login via Telegram</Trans>}
        </TelegramButton>
      </TelegramLoginWidget>
    </Column>
  )
}

const StyledIcon = styled(Icons.TelegramSquareIcon)`
  transform: scale(1.4);
  margin-right: 8px;

  path:nth-child(1) {
    fill: transparent;
  }
`

export default SocialAuth
