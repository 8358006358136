import Button from 'components/Button'
import Modal, { ModalBody, ModalProps } from 'components/Modal'
import Text from 'components/Text'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmUnLinkTelegramModal: React.FC<
  ModalProps<{
    onDisconnect: () => void
  }>
> = ({ onDismiss, data: { onDisconnect = () => {} } }) => {
  const { t } = useTranslation()

  return (
    <Modal
      minWidth={['100% !important', '', '440px !important']}
      maxWidth={['100% !important', '', '440px !important']}
      onDismiss={onDismiss}
    >
      <ModalBody p="24px">
        <Text bold>{t('Disconnect Telegram account')}</Text>

        <Text fontWeight={300} lineHeight={1.2} my="24px" small color="textTertiary">
          {t('You are about to disconect the Telegram account, are you sure about that?')}
        </Text>

        <Button mx="20px" style={{ borderRadius: '4px' }} onClick={onDisconnect} variant="error">
          <Text bold small>
            {t('Yes, Disconnect')}
          </Text>
        </Button>

        <Button onClick={onDismiss} variant="text">
          <Text small color="textSubtle">
            {t('No, Keep Telegram account')}
          </Text>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmUnLinkTelegramModal
