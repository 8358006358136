import Box from 'components/Box/Box'
import { TelegramLoginPayload } from 'config/types/telegram'
import { useBuildTeleAppData, useIsTelegramOrigin } from 'hooks/useTelegram'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { HunnyToast } from 'utils/toastify'
import { useTranslation } from 'react-i18next'
import { serializeTelegram } from 'utils/urlHelper'
import { ButtonProps } from '../../../../components/Button/types'

interface TelegramLoginWidgetProps extends ButtonProps {
  bot: string
  onLogin: (webApp: string, user: TelegramLoginPayload) => void
  dataAuthUrl?: string
  version?: number
  lang?: string
}

const TelegramLoginWidget: React.FC<React.PropsWithChildren<TelegramLoginWidgetProps>> = ({
  bot,
  onLogin,
  dataAuthUrl,
  children,
  version = 22,
  lang = 'en',
  disabled,
}) => {
  const containerRef = useRef(null)
  const [loaded, setLoad] = useState(null)
  const { payload, webAppData } = useBuildTeleAppData()
  const isTelegramOrigin = useIsTelegramOrigin()
  const { t } = useTranslation()

  useEffect(() => {
    if (containerRef.current && bot && !isTelegramOrigin) {
      window.onTelegramAuth = (user) => {
        const payload = {
          auth_date: user.auth_date || 0,
          hash: user.hash || '',

          id: user.id || 0,
          username: user.username || '',
          first_name: user.first_name || '',
          last_name: user.last_name || '',
          photo_url: user.photo_url || '',
        }

        return onLogin(serializeTelegram(user), payload)
      }

      const script = document.createElement('script')
      script.src = `https://telegram.org/js/telegram-widget.js?${version}`
      script.setAttribute('data-telegram-login', bot)
      script.setAttribute('data-userpic', 'false') // user's avatar photo

      script.setAttribute('data-size', 'large')
      script.setAttribute('data-radius', '0')

      script.setAttribute('data-request-access', 'write')
      script.setAttribute('data-lang', lang)

      if (dataAuthUrl !== undefined) {
        script.setAttribute('data-auth-url', dataAuthUrl)
      } else {
        script.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
      }
      script.async = true
      containerRef.current.appendChild(script)
      setLoad(true)

      // Cleanup the script when the component unmounts
      return () => {
        if (containerRef.current) {
          containerRef.current.removeChild(script)
          setLoad(false)
        }
      }
    }
    if (isTelegramOrigin) {
      setLoad(true)
    }
  }, [bot, onLogin, dataAuthUrl, lang, isTelegramOrigin])

  return (
    <Wrapper
      onClick={() => {
        if (isTelegramOrigin) {
          if (!webAppData) {
            HunnyToast.error('Telegram', t('Your Data Not found'))
          } else {
            onLogin(webAppData, payload)
          }
        }
      }}
      disabled={!loaded || disabled}
    >
      {!isTelegramOrigin && <WrapperScript ref={containerRef} />}
      <WrapContent>{children}</WrapContent>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ disabled: boolean }>`
  position: relative;
  width: fit-content;
  overflow: hidden;

  ${({ disabled }) =>
    disabled
      ? css`
          user-select: none;
        `
      : css`
          cursor: pointer;
        `}
`

const WrapperScript = styled(Box)`
  opacity: 0.01;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const WrapContent = styled(Box)``

export default TelegramLoginWidget
