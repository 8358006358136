import FingerprintJS, { prepareForSources, sources, loadSources } from '@fingerprintjs/fingerprintjs'

let Visitor: any

export async function getID(): Promise<string> {
  if (!Visitor) {
    await prepareForSources(100)
    const getComponents = loadSources(sources as any, {}, [
      // 'fonts',
      // 'domBlockers',
      // 'fontPreferences',
      // 'audio',
      // 'screenFrame',
      // 'osCpu',
      // 'languages',
      // 'colorDepth',
      // 'deviceMemory',
      // 'screenResolution',
      // 'hardwareConcurrency',
      // 'timezone',
      // 'sessionStorage',
      // 'localStorage',
      // 'indexedDB',
      // 'openDatabase',
      // 'cpuClass',
      // 'platform',
      // 'plugins',
      // 'canvas',
      // 'touchSupport',
      // 'vendor',
      // 'vendorFlavors',
      // 'cookiesEnabled',
      // 'colorGamut',
      // 'invertedColors',
      // 'forcedColors',
      // 'monochrome',
      // 'contrast',
      // 'reducedMotion',
      // 'hdr',
      // 'math',
      // 'videoCard',
      // 'pdfViewerEnabled',
      // 'architecture',
    ])
    const components = await getComponents()

    Visitor = {
      components,
      visitorId: FingerprintJS.hashComponents(components),
    }
  }
  return Visitor.visitorId
}
