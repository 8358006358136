import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import { StyledControlContainer } from 'components/FormControl/styled'
import Image from 'components/Image'
import NumericalInput from 'components/Input/NumericalInput'
import { InputContainer, InputLabel } from 'components/Input/styled'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenSelect from 'components/TokenSelect'
import FormValidator from 'config/constants/formValidator'
import { FIAT_NETWORK } from 'config/constants/network'
import { RequiredProvidePhoneNumberBankCodes } from 'config/constants/payment'
import { Token, TokenAmount } from 'config/types'
import { FiatMethodCategory, WithdrawFiatPaymentMethod, WithdrawFiatPaymentTypeEnums } from 'config/types/payment'
import { FiatPaymentTransaction } from 'config/types/transaction'
import { ValidationError } from 'config/types/validator'
import useForm from 'hooks/useForm'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter, RowMiddle } from 'layout/Components/Row'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { useFiatCurrencies, useTokenInfo } from 'state/app/hooks'
import { useAuthBy } from 'state/auth/hooks'
import { useTokenAppBalance } from 'state/profile/hooks'
import { usePaymentSelectedFiatCurrency } from 'state/session/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import base from 'theme/base'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'
import GameInfoMessage from 'views/GameDetails/GameInfoMessage'
import PaymentMethodCard, { Loader, PaymentMethodCardContainer } from '../../Deposit/DepositByFiat/PaymentMethodCard'
import TraditionalWithdrawButton from './TraditionalWithdrawButton'
import WalletWithdrawButton from './WalletWithdrawButton'
import WithdrawInProgressModal from './WithdrawInProgressModal'
import { useWithdrawInfo } from './hooks'

const WithdrawByFiat = () => {
  const listFiatCurrencies = useFiatCurrencies()
  const { execute } = useRequest()
  const { t } = useTranslation()
  const userDefaultSelectCurrency = usePaymentSelectedFiatCurrency()
  const [onPresentWithdrawInProgressModal] = useModal(WithdrawInProgressModal)

  const [selectedCurrency, setSelectedCurrency] = useState<Token>(userDefaultSelectCurrency)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<WithdrawFiatPaymentMethod>(null)
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<[string, WithdrawFiatPaymentMethod[]][]>(null)
  const [filterOnlineBankings, setFilterOnlineBankings] = useState<string>('')
  const [withdrawFee, setWithdrawFee] = useState('')
  const [isFetchingFiatMethods, setIsFetchingFiatMethods] = useState(false)
  const currencyInfo = useTokenInfo(selectedCurrency)

  const handleWithdrawAmountChanged = (value) => {
    const fee = new BigNumber(value)
      .dividedBy(100)
      .multipliedBy(selectedPaymentMethod?.feePercent)
      .plus(selectedPaymentMethod?.feeAmount)
      .toFixed(0, BigNumber.ROUND_UP)
    const totalAmount = new BigNumber(value).minus(fee).toFixed(0)
    setWithdrawFee(fee.toString())
    setTotalWithdrawAmount(totalAmount.toString())
    controls.withdrawAmount.onValueChanged(value)
  }

  const handleConfirmWithdraw = async (withdrawTransaction: FiatPaymentTransaction) => {
    const response = await execute(PaymentService.getWithdrawal(withdrawTransaction.code))
    onPresentWithdrawInProgressModal({
      paymentFiatTransaction: {
        ...withdrawTransaction,
        method: selectedPaymentMethod.type,
        name: selectedPaymentMethod.name,
      },
      transactionDetails: response?.data,
    })
  }

  const currencyAppBalanceAmount = useTokenAppBalance(selectedCurrency)
  const { lockAmount } = useWithdrawInfo(selectedCurrency)
  const selectTokenAmount: TokenAmount = {
    amount: new BigNumber(currencyAppBalanceAmount.amount).minus(lockAmount),
    token: currencyAppBalanceAmount.token,
  }

  const { isAuthByEmail, isAuthByWallet, isAuthByTelegram } = useAuthBy()
  const { states, controls, validateAll, isValid } = useForm({
    withdrawAmount: {
      value: '',
      validators: [
        FormValidator.min(selectedPaymentMethod?.minAmount),
        FormValidator.max(selectedPaymentMethod?.maxAmount),
        FormValidator.required,
        FormValidator.requiredNumber,
        FormValidator.gt(selectTokenAmount.amount?.toString()),
      ],
    },
    bankAccountName: {
      value: '',
      validators: [FormValidator.required, FormValidator.inputLength({ min: 1, max: 50 })],
    },
    bankAccountNumber: {
      value: '',
      validators: [
        FormValidator.required,
        FormValidator.requiredInteger,
        FormValidator.inputLength({ min: 1, max: 50 }),
      ],
    },
    holderMobileNumber: {
      value: '',
      validators: RequiredProvidePhoneNumberBankCodes.includes(selectedPaymentMethod?.code)
        ? [FormValidator.required, FormValidator.requiredInteger, FormValidator.inputLength({ min: 1, max: 30 })]
        : [],
    },
  })

  const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(states.withdrawAmount.value)
  const formatErrorMessage = (errors: ValidationError[], optionalFields?: { [key: string]: string }) => {
    if (errors[0] === ValidationError.NotEnough || errors[0] === ValidationError.Insufficient) {
      return t(
        'Total withdraw amount has to be between {{ minAmount }} {{ currency }} and {{ maxAmount }} {{ currency }}',
        {
          minAmount: getFullDisplayBalance(selectedPaymentMethod.minAmount, 0, 5),
          maxAmount: getFullDisplayBalance(selectedPaymentMethod.maxAmount, 0, 5),
          currency: selectedPaymentMethod.currency,
        },
      )
    }

    if (errors[0] === ValidationError.Required) {
      return t(optionalFields?.[errors[0]] || '')
    }
    if (errors[0] === ValidationError.TooLong || errors[0] === ValidationError.TooShort)
      return <Trans>Excess required length</Trans>
    if (errors[0] === ValidationError.GreatThan) return <Trans>Insufficient balance</Trans>
    if (errors[0] === ValidationError.RequiredInteger) return t(optionalFields?.[errors[0]])
  }

  const isNotAbleToExecuteWithdraw =
    !isValid ||
    !states.bankAccountName.value ||
    !states.withdrawAmount.value ||
    (!states.holderMobileNumber.value && RequiredProvidePhoneNumberBankCodes.includes(selectedPaymentMethod?.code)) ||
    !states.bankAccountNumber.value ||
    new BigNumber(totalWithdrawAmount).eq(0)

  useEffect(() => {
    const fetch = async () => {
      setIsFetchingFiatMethods(true)
      const response = await execute(PaymentService.getWithdrawFiatBankMethodList({ currency: selectedCurrency.code }))
      if (response?.data) {
        setPaymentMethodOptions(response.data)
      }

      setIsFetchingFiatMethods(false)
    }

    fetch()
  }, [selectedCurrency])

  const isExceedMaxLength = (event, maxLengthNumber) => {
    if (event.target.value.length > maxLengthNumber) {
      event.preventDefault()
      event.stopPropagation()
      return true
    }
    return false
  }

  return (
    <>
      <StyledContainer openType="grow">
        <StyledControlContainer state={null} mt="10px">
          <InputLabel>
            <Trans>Withdraw Currency</Trans>:
          </InputLabel>

          <TokenSelect
            options={listFiatCurrencies}
            onTokenChange={(token) => {
              setSelectedPaymentMethod(null)
              setSelectedCurrency(token)
              controls.bankAccountName.clear()
              controls.bankAccountNumber.clear()
              controls.holderMobileNumber.clear()
              controls.withdrawAmount.clear()
            }}
            value={selectedCurrency}
          />
        </StyledControlContainer>

        {((!paymentMethodOptions || !paymentMethodOptions?.length) && !isFetchingFiatMethods) ||
          (currencyInfo.disabledWithdrawal && (
            <GameInfoMessage
              variant="error"
              maxWidth="100% !important"
              my="12px"
              message={t('Withdraw with {{tokenName}}({{network}}) has been suspended.', {
                tokenName: selectedCurrency.name,
                network: FIAT_NETWORK.networkInfo.shortName,
              })}
            />
          ))}
        {paymentMethodOptions?.length && !currencyInfo.disabledWithdrawal && (
          <>
            <Box>
              <Column style={{ gap: '12px' }}>
                <InputLabel
                  fontSize="12px"
                  fontWeight={500}
                  letterSpacing={-0.24}
                  lineHeight="normal"
                  color="textSubtle"
                >
                  <Trans>Choose a payment method</Trans>:
                </InputLabel>
                {!isFetchingFiatMethods &&
                  !selectedPaymentMethod &&
                  paymentMethodOptions?.map(([paymentMethodType, paymentMethodList]) => {
                    const paymentMethodAsset = FiatMethodCategory.find((method) =>
                      method.methods.includes(Number(paymentMethodType)),
                    )
                    if (paymentMethodAsset)
                      return (
                        <>
                          <PaymentMethodCardContainer
                            title={paymentMethodAsset.title}
                            Icon={paymentMethodAsset.icon}
                            maxHeight={
                              (paymentMethodType as unknown as WithdrawFiatPaymentTypeEnums) ==
                              WithdrawFiatPaymentTypeEnums.OnlineBanking
                                ? '388px'
                                : '200px'
                            }
                            overflowY="auto"
                            onBankSearchChanged={
                              (paymentMethodType as unknown as WithdrawFiatPaymentTypeEnums) ==
                              WithdrawFiatPaymentTypeEnums.OnlineBanking
                                ? (value) => setFilterOnlineBankings(value)
                                : null
                            }
                          >
                            {paymentMethodList
                              ?.filter((item) =>
                                item.type == WithdrawFiatPaymentTypeEnums.OnlineBanking
                                  ? item.name.toLocaleLowerCase().includes(filterOnlineBankings.toLocaleLowerCase())
                                  : item,
                              )
                              ?.map((item) => (
                                <PaymentMethodCard
                                  method={item}
                                  onItemSelected={(selectedItem) => setSelectedPaymentMethod(selectedItem)}
                                />
                              ))}
                          </PaymentMethodCardContainer>
                          <Divider />
                        </>
                      )

                    return null
                  })}

                {isFetchingFiatMethods && <Loader />}
              </Column>

              {selectedPaymentMethod && (
                <Column style={{ gap: '20px' }}>
                  <RowBetween
                    borderRadius={base.radii.tiny}
                    border={`1px solid ${colors.stroke}`}
                    padding="12px"
                    maxHeight="48px"
                    onClick={() => setSelectedPaymentMethod(null)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <RowCenter>
                      <Box width={'34px'} height={'32px'} mr="4px">
                        <Image src={selectedPaymentMethod.thumbnail} width={32} height={30} />
                      </Box>
                      <Column>
                        <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28} lineHeight="normal">
                          {selectedPaymentMethod.name}
                        </Text>
                      </Column>
                    </RowCenter>

                    <Icons.ArrowDownIcon size="20px" />
                  </RowBetween>

                  <FormControl
                    state={states.bankAccountName}
                    formatErrorMessage={(errors) =>
                      formatErrorMessage(errors, {
                        [ValidationError.Required]: 'Bank account name is required',
                      })
                    }
                    label={t('Bank account name')}
                  >
                    <FormInput
                      tabIndex={1}
                      control={{
                        ...controls.bankAccountName,
                        onValueChanged(value, event) {
                          const isExceed = isExceedMaxLength(event, 50)
                          if (!isExceed) controls.bankAccountName.onValueChanged(value)
                        },
                      }}
                      placeholder={t('Enter your bank account name')}
                      name="bankAccountName"
                      maxLength={50}
                    />
                  </FormControl>

                  <FormControl
                    state={states.bankAccountNumber}
                    formatErrorMessage={(errors) =>
                      formatErrorMessage(errors, {
                        [ValidationError.Required]: 'Account number is required',
                        [ValidationError.RequiredInteger]: 'Account number should be numeric',
                      })
                    }
                    label={t('Bank account number')}
                  >
                    <FormInput
                      tabIndex={0}
                      placeholder={t('Enter your bank account number')}
                      control={{
                        ...controls.bankAccountNumber,
                        onValueChanged(value, event) {
                          const isExceed = isExceedMaxLength(event, 50)
                          if (!isExceed) controls.bankAccountNumber.onValueChanged(value)
                        },
                      }}
                      name="bankAccountNumber"
                      maxLength={50}
                    />
                  </FormControl>

                  {RequiredProvidePhoneNumberBankCodes.includes(selectedPaymentMethod.code) && (
                    <FormControl
                      state={states.holderMobileNumber}
                      formatErrorMessage={(errors) =>
                        formatErrorMessage(errors, {
                          [ValidationError.Required]: 'Mobile Number is required',
                          [ValidationError.RequiredInteger]: 'Mobile Number should be numeric',
                        })
                      }
                      label="Mobile Number:"
                    >
                      <FormInput
                        tabIndex={0}
                        placeholder={t('Enter your mobile number')}
                        control={{
                          ...controls.holderMobileNumber,
                          onValueChanged(value, event) {
                            const isExceed = isExceedMaxLength(event, 30)
                            if (!isExceed) controls.holderMobileNumber.onValueChanged(value)
                          },
                        }}
                        name="holderMobileNumber"
                        maxLength={30}
                      />
                    </FormControl>
                  )}

                  <Box>
                    <FormControl
                      label=""
                      formatErrorMessage={(errors) =>
                        formatErrorMessage(errors, { [ValidationError.Required]: 'Withdraw amount is required' })
                      }
                      state={states.withdrawAmount}
                    >
                      <RowBetween>
                        <InputLabel>
                          <Trans>Amount</Trans>
                        </InputLabel>
                        <Text fontSize="12px" fontWeight={500} color="textAlt1">
                          <Trans>Available</Trans>:{' '}
                          <span style={{ color: colors.text }}>
                            {getFullDisplayBalance(selectTokenAmount.amount).toString()} {selectedCurrency.name}
                          </span>
                        </Text>
                      </RowBetween>
                      <InputContainer paddingY="14px" px="20px" pr="0px" height="48px">
                        <NumericalInput
                          autoFocus={false}
                          tabIndex={0}
                          value={states.withdrawAmount.value}
                          onValueChanged={handleWithdrawAmountChanged}
                          onErrorChanged={controls.withdrawAmount.onErrorChanged}
                          validators={controls.withdrawAmount.validators}
                          validateOnchange
                          placeholder={states.withdrawAmount.value || '0'}
                          maxLength={8}
                          pattern="^[0-9]*$"
                        />
                        <Text
                          fontSize="16px"
                          fontWeight={500}
                          letterSpacing={-0.28}
                          lineHeight="normal"
                          padding="15px 20px"
                        >
                          {selectedCurrency.name}
                        </Text>
                      </InputContainer>
                    </FormControl>

                    <RowMiddle>
                      <Text fontSize="10px" color="textAlt1" mr="4px" lineHeight="normal" fontWeight={500}>
                        <Trans>Locked by Active Bonus</Trans>:{' '}
                        {Number.isNaN(lockAmount.toNumber()) ? '...' : lockAmount.toNumber()} {selectedCurrency?.name}
                      </Text>
                    </RowMiddle>
                  </Box>
                </Column>
              )}
            </Box>

            {states.withdrawAmount.value && (
              <Column
                background={colors.backgroundAlt5}
                borderRadius={base.radii.small}
                padding="12px"
                style={{ gap: '12px' }}
              >
                <RowBetween>
                  <Text color="textSubtle" fontSize="12px" fontWeight={400}>
                    <Trans>Withdraw amount</Trans>:
                  </Text>
                  <Text color="textTertiary" fontSize="12px" fontWeight={600} style={{ wordWrap: 'break-word' }}>
                    {Number(totalWithdrawAmount) < 0 ? '0' : totalWithdrawAmount || '0'} {selectedCurrency.name}
                  </Text>
                </RowBetween>

                <RowBetween>
                  <Text color="textSubtle" fontSize="12px" fontWeight={400}>
                    {Number(selectedPaymentMethod?.feeAmount) > 0 &&
                      Number(selectedPaymentMethod?.feePercent) > 0 &&
                      t('Merchant fee ({{ percentFee }}% {{ amount }} {{ currency }})', {
                        percentFee: selectedPaymentMethod?.feePercent,
                        amount: selectedPaymentMethod?.feeAmount || '0',
                        currency: selectedPaymentMethod?.currency,
                      })}
                    {Number(selectedPaymentMethod?.feeAmount) === 0 &&
                      t('Merchant fee ({{ percentFee }}%)', {
                        percentFee: selectedPaymentMethod?.feePercent,
                      })}
                    {Number(selectedPaymentMethod?.feePercent) === 0 &&
                      t('Merchant fee ({{amount}} {{ currency }})', {
                        currency: selectedPaymentMethod?.currency,
                        amount: selectedPaymentMethod?.feeAmount || '0',
                      })}
                  </Text>

                  <RowCenter>
                    <Text color="textTertiary" fontSize="12px" fontWeight={600} mr="2px">
                      {withdrawFee || '0'} {selectedCurrency.name}
                    </Text>
                    <Icons.HelpOutlineIcon
                      width="16px"
                      height="16px"
                      fill={colors.textSubtle}
                      data-tooltip-id={'common-tip-left'}
                      data-tooltip-content={t(
                        'If the calculated fee contains decimals, the fee will be rounded up to integer',
                      )}
                    />
                  </RowCenter>
                </RowBetween>

                <RowBetween>
                  <Text color="textSubtle" fontSize="12px" fontWeight={400}>
                    <Trans>Total withdrawal amount</Trans>:
                  </Text>

                  <Text color="warning" fontSize="12px" fontWeight={600} style={{ wordWrap: 'break-word' }}>
                    {/^0+$/.test(states.withdrawAmount.value) ? ' 0' : states.withdrawAmount.value}{' '}
                    {selectedCurrency?.name}
                  </Text>
                </RowBetween>
              </Column>
            )}

            {(isAuthByEmail || isAuthByTelegram) && (
              <TraditionalWithdrawButton
                payload={{
                  selectedCurrency,
                  fee: withdrawFee,
                  value: states.withdrawAmount.value,
                  bankAccountName: states.bankAccountName?.value,
                  bankAccountNumber: states.bankAccountNumber?.value?.toString(),
                  accountMobileNumber: states.holderMobileNumber?.value,
                  bankCode: selectedPaymentMethod?.code,
                }}
                validateAll={validateAll}
                onSuccess={(fiatPaymentTransaction) => handleConfirmWithdraw(fiatPaymentTransaction)}
                disabled={isNotAbleToExecuteWithdraw}
              />
            )}
            {isAuthByWallet && (
              <WalletWithdrawButton
                payload={{
                  selectedCurrency,
                  fee: withdrawFee,
                  value: states.withdrawAmount.value,
                  bankAccountName: states.bankAccountName?.value,
                  bankAccountNumber: states.bankAccountNumber?.value?.toString(),
                  accountMobileNumber: states.holderMobileNumber?.value,
                  bankCode: selectedPaymentMethod?.code,
                }}
                validateAll={validateAll}
                onSuccess={(fiatPaymentTransaction) => handleConfirmWithdraw(fiatPaymentTransaction)}
                disabled={isNotAbleToExecuteWithdraw}
              />
            )}
          </>
        )}
      </StyledContainer>
    </>
  )
}

export default WithdrawByFiat
const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 450px;
  }
`

const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background: ${({ theme: { colors } }) => colors.buttonBackgroundAlt2};
`
