import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Link from 'components/Link/Link'
import Tabs from 'components/Tabs'
import Text from 'components/Text/Text'
import { RouteConfig } from 'config/constants/route'
import { Token } from 'config/types'
import { WageringConditionBonus } from 'config/types/bonus/userBonus'
import { useCloseAllModal } from 'hooks/useModal'
import { Trans } from 'react-i18next'
import { useActiveBonusInWageringProcess } from 'state/bonus/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import HunnyTooltip from 'components/Tooltip/Tooltip'
import theme from 'theme'
import TransText from 'components/Text/TransText'
import { emptyBonus } from '../DepositCryptos/hooks'
import WaitingDepositBonusItem from './WaitingDepositBonusItem'

const WarningTooltip = () => {
  return (
    <HunnyTooltip
      id="ctp-deposit-bonus"
      place="top"
      offset={6}
      style={{
        padding: '6px 12px',
        background: colors.modalHeaderBackground,
        borderRadius: theme.radii.small,
        width: '320px',
      }}
    >
      <TransText fontSize="12px" fontWeight={300}>
        To activate this bonus, you must either complete the current wagering bonus or cancel it.
      </TransText>
    </HunnyTooltip>
  )
}

const WaitingDepositBonusList: React.FC<
  BoxProps & {
    selectedToken: Token
    disabled?: boolean
    bonuses: WageringConditionBonus[]
    selectedBonus: WageringConditionBonus
    handleSelectedBonus: (bonus: WageringConditionBonus) => Promise<void>
    isSelectedBonusExpired: boolean
  }
> = ({ selectedToken, selectedBonus, bonuses, isSelectedBonusExpired, disabled, handleSelectedBonus, ...props }) => {
  const closeAllModal = useCloseAllModal()
  const bonusWagering = useActiveBonusInWageringProcess()

  return (
    bonuses.length > 0 && (
      <Box {...props} width="100%">
        <Flex alignItems="center" justifyContent="space-between" mb="12px">
          <Text small bold>
            <Trans>Choose a bonus</Trans>
          </Text>

          <Link
            href={RouteConfig.BonusCenter}
            onClick={() => {
              closeAllModal()
            }}
          >
            <Flex alignItems="center">
              <Text fontSize="12px" color="textAlt1" mr="4px">
                <Trans>Bonus Center</Trans>
              </Text>
              <Icons.ChevronRightIcon height="10px" fill={colors.textAlt1} />
            </Flex>
          </Link>
        </Flex>

        <StyledTabs
          value={selectedBonus}
          onTagChanged={handleSelectedBonus}
          indicator={<></>}
          backgroundBlurColor={colors.modalBackground}
        >
          {bonuses.map((bonus) => {
            return (
              <WaitingDepositBonusItem
                disabled={disabled || (bonusWagering && bonus?.isCreaditReward)}
                key={bonus.id}
                value={bonus}
                height="132px"
                maxWidth="114px"
                marginRight="8px"
                bonus={bonus}
                selectedToken={selectedToken}
                isSelected={bonus === selectedBonus}
                data-tooltip-id={
                  bonusWagering && bonus?.isCreaditReward && bonus.id !== emptyBonus.id ? 'ctp-deposit-bonus' : null
                }
              />
            )
          })}
        </StyledTabs>

        <WarningTooltip />
        {isSelectedBonusExpired ? (
          <Flex mt="8px" alignItems="center">
            <Box minWidth="16px" mr="4px">
              <Icons.WarningIcon width="16px" fill={colors.error} />
            </Box>
            <Text color="error" fontSize="12px" lineHeight="14.5px">
              <Trans>Your bonus has been ended. Please choose another bonus</Trans>
            </Text>
          </Flex>
        ) : (
          <Flex mt="8px">
            <Box minWidth="16px" mr="4px">
              <Icons.WarningIcon width="16px" fill={colors.textSubtle} />
            </Box>
            <Text color="textSubtle" fontSize="12px" lineHeight="14.5px">
              <Trans>
                Don't change the selected bonus until your deposit is credited to your game balance to activate it
              </Trans>
            </Text>
          </Flex>
        )}
      </Box>
    )
  )
}

const StyledTabs = styled(Tabs)`
  .tabs-right-blur {
    background: radial-gradient(
      586.71% 162.98% at 460.71% 50%,
      ${({ theme: { colors } }) => colors.modalBackground} 65.62%,
      rgba(22, 28, 36, 0) 79.69%
    );
    width: 40px;
  }

  .tabs-left-blur {
    background: radial-gradient(
      586.71% 162.98% at 460.71% 50%,
      ${({ theme: { colors } }) => colors.modalBackground} 65.62%,
      rgba(22, 28, 36, 0) 79.69%
    );
    width: 40px;
    transform: rotate(180deg) translateY(50%);
  }
`

export default WaitingDepositBonusList
