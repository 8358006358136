import axios, { AxiosRequestConfig } from 'axios'
import { BASE_API, EXCLUDE_API_ERROR, EXCLUDE_API_ERROR_LOG, USER_BANNED_ERROR_CODE } from 'config/constants/server'
import { getLangCodeByBCP47 } from 'utils'
import { getCookie } from 'utils/cookie'
import { SentryHelper } from 'utils/sentryHelper'
import { HunnyToast } from 'utils/toastify'
import { AuthRefreshHelper } from './AuthRefreshHelper'
import { BaseRequest, hunnyAxios } from './BaseRequest'
import { PromiseQueue } from './PromiseQueue'
import Mapper from './mapper/Mapper'
import { BaseResponse, HunnyRequest } from './types'

const REQUEST_LIMIT = 4
const requestQueue = new PromiseQueue(REQUEST_LIMIT)

export abstract class HunnyPlayRequest extends BaseRequest {
  protected async _get(url: string, params?: any, config?: AxiosRequestConfig, mapperKey?: string): Promise<any> {
    try {
      let _config = { ...config }
      const mapper = Mapper.getMapper(mapperKey || url)
      if (mapper) {
        _config = { ..._config, transformResponse: mapper }
      }

      const result = await hunnyAxios.get(url, { params, ..._config })

      SentryHelper.addBreadcrumbs({
        category: 'siteCallAPI.GET',
        message: `GET ${url} [${result?.status}]`,
      })

      return result.data
    } catch (error) {
      SentryHelper.captureAPIClientError({ url, error })
      return null
    }
  }

  protected _post(
    url: string,
    payload?: any,
    config = {} as AxiosRequestConfig & { excludeErrors?: string[]; authRequest?: boolean },
    mapperKey?: string,
  ): HunnyRequest<BaseResponse<any>> {
    const _source = this.AxiosCancelToken

    const call = async (): Promise<BaseResponse<any>> => {
      const response = await this._request(
        url,
        payload,
        {
          ...config,
          cancelToken: _source.token,
        },
        mapperKey,
      )

      return response
    }

    return {
      call,
      cancel: () => {
        _source.cancel()
      },
    }
  }

  protected async _request(
    url: string,
    payload?: any,
    config = {} as AxiosRequestConfig & { excludeErrors?: string[]; disabledToast?: boolean; authRequest?: boolean },
    mapperKey?: string,
  ): Promise<BaseResponse<any>> {
    const request = async () => {
      try {
        const mapper = Mapper.getMapper(mapperKey || url)
        const { excludeErrors = [], ...axiosConfig } = config
        const _config = this.buildConfig(axiosConfig, mapper)

        const result = await this.callRequest(url, _config, payload)

        if (result?.data?.code === 'success') return result.data

        if (result?.data?.code === 'error_country_banned') {
          return result.data
        }

        if (
          result?.data &&
          result.data.code === USER_BANNED_ERROR_CODE &&
          ![...excludeErrors].includes(result.data.code)
        ) {
          window.location.replace('/403')
        } else if (result?.data && result.data.code !== 'success') {
          if (![...EXCLUDE_API_ERROR, ...excludeErrors].includes(result.data.code)) {
            if (!config.disabledToast) HunnyToast.error(result.data.message)
          }

          if (![...EXCLUDE_API_ERROR_LOG, ...excludeErrors].includes(result.data.code)) {
            SentryHelper.captureAPIClientError({
              url,
              response: result,
            })
          }
        }

        return result.data
      } catch (error) {
        if (!axios.isCancel(error))
          SentryHelper.captureFeatureClientError({
            feature: 'HunnyPlay Request',
            error,
            options: {
              title: `API ${url} Unknow error`,
              payload: {
                payload,
                config,
              },
            },
          })
        return null
      }
    }

    if (config.authRequest) {
      return requestQueue.call(AuthRefreshHelper.buildRequestFn(request, url))
    }

    return requestQueue.call(request)
  }

  protected async callRequest(url: string, config: AxiosRequestConfig, payload?: any) {
    const result = await hunnyAxios.post(url, payload, config)

    SentryHelper.addBreadcrumbs({
      category: 'siteCallAPI.POST',
      level: result?.data?.code === 'success' ? 'info' : 'error',
      message: `POST ${url} [${result?.data?.code || 'unknow code'}] [${result?.status}]`,
    })
    return result
  }

  protected buildConfig(config: AxiosRequestConfig, mapper: any): AxiosRequestConfig {
    let _config = {
      baseURL: typeof window === 'undefined' ? process.env.INTERNAL_API || BASE_API : BASE_API,
      withCredentials: true,
      ...config,
      headers: {
        'Api-Language':
          typeof window === 'undefined' ? 'en' : getCookie('APP_LANG') || getLangCodeByBCP47(navigator.language),
      },
    }

    if (mapper) {
      _config = { ..._config, transformResponse: mapper }
    }

    return _config
  }
}
