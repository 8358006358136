import * as Sentry from '@sentry/nextjs'
import { AxiosResponse } from 'axios'
import { BaseResponse } from 'services/types'

type FeatureContext = {
  feature: string
  error: any
  options?: { title?: string; payload?: any }
}

type APIContext = {
  url: string
  error?: any
  response?: AxiosResponse<BaseResponse<any>, any>
}
export class SentryHelper {
  private static _captureError(error: Error, title: string, tags: any, fingerprint: string, extra: any) {
    const err = error
    err.name = title

    Sentry.captureException(err, {
      tags: { ...tags, title },
      extra,
      fingerprint: [fingerprint],
    })
  }

  // CLIENT
  public static captureFeatureClientError({ error, feature, options }: FeatureContext) {
    const tags = { type: 'feature', feature, system: 'CLIENT' }
    const fingerprint = `Feature ${feature} Error`
    const title = `[CLIENT] Feature Error: ${feature} ${options?.title ? ` (${options.title})` : ''}`
    SentryHelper._captureError(error, title, tags, fingerprint, options?.payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static captureAPIClientError({ url, error, response }: APIContext) {
    // Turn off
    // const err = error || new Error(`API Failed: ${response?.data.message || 'unknow message'}`)
    // const tags = {
    //   type: 'api_failed',
    //   apiError: err?.code || response?.data.code || 'unknow',
    //   system: 'CLIENT',
    // }
    // const fingerprint = `API ${url} Failed`
    // const title = `[CLIENT] API Error: ${url}`
    // SentryHelper._captureError(err, title, tags, fingerprint, response?.data)
  }

  // SERVER
  public static captureAPIServerError({ url, error, response }: APIContext) {
    const err = error || new Error(`API Failed: ${response?.data.message || 'unknow message'}`)
    const tags = {
      type: 'api_failed',
      apiError: err?.code || response?.data.code || 'unknow',
      system: 'Server',
    }
    const fingerprint = `API ${url} Failed`
    const title = `[SERVER] API Error: ${url}`

    SentryHelper._captureError(err, title, tags, fingerprint, response?.data)
  }

  public static captureFeatureServerError({ error, feature, options }: FeatureContext) {
    const tags = { type: 'feature', feature, system: 'SERVER' }
    const fingerprint = `Feature ${feature} Error`
    const title = `[SERVER] Feature Error: ${feature} ${options?.title ? ` (${options.title})` : ''}`
    SentryHelper._captureError(error, title, tags, fingerprint, options?.payload)
  }

  public static addBreadcrumbs({
    category,
    message,
    type = 'default',
    level = 'info',
  }: {
    category: string
    message: string
    type?: string
    level?: Sentry.SeverityLevel
  }) {
    Sentry.addBreadcrumb({
      category,
      message,
      type,
      level,
    })
  }
}
