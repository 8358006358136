import { ALL_GAME_TAG } from 'config/constants/gameTags'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { ScreenTag } from 'config/types'
import {
  Game,
  GameDemoSession,
  GameDescription,
  GameDetail,
  GameMerchantCode,
  GameRatingInfo,
  GameSession,
  GameType,
  InhouseGameDetails,
} from 'config/types/game'
import { BetDetails } from 'config/types/profile'
import { BaseResponse, Paging } from 'services/types'
import { formatApiNetworkField, range } from 'utils'

export const GameMaintenanceStatusResponse = 1

export const parseScreenTags = (data: any[]): ScreenTag[] => {
  let items = (data || [])?.map((item) => ({
    tag: item.tag_code,
    childTags: item.child_tags.split(' '),
  }))

  if (!items.find((item) => item.tag === ALL_GAME_TAG)) {
    items = [
      {
        tag: ALL_GAME_TAG,
        childTags: [],
      },
      ...items,
    ]
  }
  return items
}

export const ScreenTagMapper = (rawResponse: string): BaseResponse<Paging<ScreenTag>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const items = parseScreenTags(response.data.items)

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items,
    },
  }
}

export const GamesMapper = (rawResponse: string): BaseResponse<Paging<Game>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: (response.data.items || response.data.games).map((item: any) => parseGame(item)),
    },
  }
}

export const FavoriteGamesMapper = (rawResponse: string): BaseResponse<Paging<Game>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: (response.data.items || response.data.games).map((item: any) => parseGame(item)),
    },
  }
}

export const GameDescriptionMapper = (rawResponse: string): BaseResponse<GameDescription> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data
      ? {
          ...response.data,
          attributes:
            response.data.attributes?.map((attribute) => {
              return {
                label: attribute.name,
                value: attribute.value,
                isHighlight: attribute.highlight,
              }
            }) || [],
        }
      : null,
  }
}

export const UserGameRatingDataMapper = (rawResponse: string): BaseResponse<number> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data?.rated || 0,
  }
}

export const GameRatingInfoMapper = (rawResponse: string): BaseResponse<GameRatingInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,

    data: range(0, 5).map(
      (index) => response?.data?.ratings.find((item) => item.rating === index + 1)?.quantity || 0,
    ) as GameRatingInfo,
  }
}

export const GameSessionMapper = (rawResponse: string): BaseResponse<GameSession> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      code: response.data.code,
      gameUrl: response.data['open_value'],
    },
  }
}

export const GameDetailMapper = (rawResponse: string): BaseResponse<GameDetail | InhouseGameDetails> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  const merchantCode = response.data?.merchant_code
  const gameType = merchantCode === GameMerchantCode.INHOUSE ? GameType.InHouse : response.data?.type

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...parseGame(response.data),
      categoryId: response.data.category_id,
      merchantCode,
      type: gameType,
      gameTags: response.data.tags,
      additionalData:
        gameType === GameType.InHouse
          ? {
              inhouseGameType: response.data.merchant_ref,
            }
          : null,
      isPlayDemo: response.data.demo_mode_available === 1,
    },
  }
}

export const parseGame = (game: any): Game => {
  return {
    code: game.code,
    name: game.name,
    providerCode: game.provider_code || 'Unknow',
    slug: game.slug,
    thumbnail: game.banner_big_url || game.banner_url,
    favoriteCount: game.favorite_count || 0,
    type: game.type || 1,
    isMaintenance: game.is_maintenance === GameMaintenanceStatusResponse,
  }
}

export const GameSessonDemoMapper = (rawResponse: string): BaseResponse<GameDemoSession> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      code: response.data.open_type,
      gameDemoURL: response.data.open_value,
    },
  }
}

export const GameDetailTopWinListMapper = (rawResponse: string): BaseResponse<Paging<BetDetails>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: (response.data.items || response.data.games).map((item: any) => parseBetDetailsItemMapper(item)),
    },
  }
}

export const LiveBetMapper = (rawResponse: string): BaseResponse<Paging<BetDetails>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: (response.data.items || response.data.games).map((item: any) => parseBetDetailsItemMapper(item)),
    },
  }
}

export const BetDetailMapper = (rawResponse: string): BaseResponse<BetDetails> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseBetDetailsItemMapper(response.data),
  }
}

export const CreditBonusRestrictedGamesMapper = (rawResponse: string): BaseResponse<string[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data.codes.map((item) => item),
  }
}

export const parseBetDetailsItemMapper = (data: any): BetDetails => {
  const betAmount = data.bet_amount_currency
  const payoutAmount = data.payout_currency

  const network = ChainIdEnum[formatApiNetworkField(data.network)] || null
  const currency = tokens[network]?.[data.currency] || null

  let game = null

  if (data?.game_info) {
    const type = data.game_info.merchant_code === GameMerchantCode.INHOUSE ? GameType.InHouse : data.game_info.type
    game = {
      ...parseGame(data.game_info),
      tag: data.game_info.tags?.split(' ')?.[0] || data?.game_info?.tags,
      type,
      inhouseGameType: type === GameType.InHouse ? data.game_info.merchant_ref : null,
    }
  }

  return {
    betId: Number(data.round_id),
    providerBetIdRef: Number(data.merchant_ref),
    game: game || null,
    bet: {
      token: currency,
      amount: betAmount,
      amountInUsd: data.bet_amount_usd,
    },
    payout: {
      token: currency,
      amount: payoutAmount,
      amountInUsd: data.payout_usd,
    },

    player: {
      avatar: data.avatar_url,
      displayName: data.display_name,
      code: data.user_code,
      settings: {
        isHideName: !data.display_name,
      },
    },

    network,
    multiplier: payoutAmount > 0 && betAmount > 0 ? Number(payoutAmount) / Number(betAmount) : 0,
    createTime: data.create_time * 1000,
  }
}
