import Box from 'components/Box/Box'
import Button from 'components/Button'
import Image from 'components/Image'
import Text from 'components/Text'
import { BASE_API } from 'config/constants/server'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { externalTelegramLink } from 'utils/telegram'
import { StyledStroke } from 'views/Referral/components/styled'

const WarningOnTelegram: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Image src="/images/login-3d-2.png" width={150} height={140} mx="auto" />

      <Box as="ul" mt="12px">
        <Text lineHeight={1.6} fontWeight={500} small color="textTertiary">
          {t(
            'We are not supporting this feature on Telegram. If you want to use this feature, please visit HunnyPlay website.',
          )}
        </Text>
      </Box>

      <StyledStroke mt="12px" mb="24px" />

      <Button
        onClick={() => {
          externalTelegramLink(`${BASE_API}?action=onLogin`)
        }}
        width="100%"
        style={{ borderRadius: '4px' }}
      >
        <Text bold small>
          {t('Open HunnyPlay Website')}
        </Text>
      </Button>
    </Box>
  )
}

export default WarningOnTelegram
