import useDebounceCallback from 'hooks/useDebounceCallback'
import Column from 'layout/Components/Column'
import { useEffect, useState } from 'react'
import WheelGameService from 'services/InhouseGameService/WheelGameService'
import { SentryHelper } from 'utils/sentryHelper'
import WheelRiskSelect from 'views/InhouseGame/Wheel/components/BetBoard/components/WheelRiskSelect'
import WheelSegmentsSelect from 'views/InhouseGame/Wheel/components/BetBoard/components/WheelSegmentsSelect'
import WheelPayoutGroup from 'views/InhouseGame/Wheel/components/GameBoard/components/WheelPayoutGroup'
import {
  WHEEL_PAYOUTS,
  WHEEL_RISK_DEFAULT,
  WHEEL_SEGMENTS_DEFAULT,
  WheelGameRiskEnum,
} from 'views/InhouseGame/Wheel/config'
import { WheelSegments } from 'views/InhouseGame/Wheel/config/types'
import { WheelProbilityFairBetInfo } from 'views/InhouseGame/config/types'

const WheelVerifyForm: React.FC<{
  clientSeed: string
  serverSeed: string
  nonce: number
  onVerified: (resultText: any) => void
  initialWheelBet?: WheelProbilityFairBetInfo
}> = ({ onVerified, clientSeed, serverSeed, nonce, initialWheelBet }) => {
  const [segments, setSegments] = useState<WheelSegments>(initialWheelBet?.data.segments || WHEEL_SEGMENTS_DEFAULT)
  const [risk, setRisk] = useState<WheelGameRiskEnum>(initialWheelBet?.data.risk || WHEEL_RISK_DEFAULT)

  const debounceCallback = useDebounceCallback()

  useEffect(() => {
    debounceCallback(async () => {
      if (risk && segments && clientSeed && serverSeed && nonce >= 0) {
        const result = await WheelGameService.verifyResult(risk, segments, clientSeed, serverSeed, nonce)

        const payouts = WHEEL_PAYOUTS[risk][segments] || []
        const payout = payouts.find((value) => value.payout === result)

        if (!payout) {
          SentryHelper.captureFeatureClientError({
            feature: 'Inhouse Game Wheel',
            error: new Error('Cannot find payout config'),
            options: {
              payload: { data: { result, risk, segments } },
            },
          })
          return
        }

        onVerified(
          <WheelPayoutGroup small padding="0px" mt="42px" segments={segments} risk={risk} activePayout={payout} />,
        )
      } else onVerified(null)
    }, 300)
  }, [risk, segments, clientSeed, serverSeed, nonce])

  return (
    <Column>
      <WheelRiskSelect risk={risk} onSelect={setRisk} mb="16px" height="44px" />
      <WheelSegmentsSelect segment={segments} onSelect={setSegments} mb="16px" dropdownPosition="top" height="44px" />
    </Column>
  )
}

export default WheelVerifyForm
