import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'
import { useAppSelector } from 'state'
import { getChainCode } from 'utils'
import { getNetworkCodeType } from 'utils/token'

export const useWithdrawInfo = (selectedToken: Token) => {
  const minWithdrawTokenAmounts = useAppSelector((state) => state.app.minWithdrawTokenAmounts)
  const selectedTokenMinWithdrawAmount = minWithdrawTokenAmounts[selectedToken.code]

  const { execute } = useRequest()
  const [withdrawFee, setWithdrawFee] = useState<string>(null)
  const [widthrawLockedAmount, setWidthrawLockedAmount] = useState<{
    lockAmount: BigNumber
    bonusId?: number
  }>({ lockAmount: new BigNumber(0) })

  useEffect(() => {
    if (selectedToken === HUSD_TOKEN) return

    const fetchFee = async () => {
      const response = await execute(
        PaymentService.getWithdrawInfo({
          chainType: getNetworkCodeType(selectedToken.network),
          chainCode: getChainCode(ChainIdEnum[selectedToken.network]),
          currency: selectedToken.code,
        }),
      )

      if (response?.data) {
        setWithdrawFee(response.data.withdrawFee)
        setWidthrawLockedAmount({
          lockAmount: new BigNumber(response.data.lockBonusAmount || 0),
          bonusId: response.data.voucherId || null,
        })
      }
    }

    fetchFee()
  }, [selectedToken])

  return useMemo(() => {
    const { lockAmount, bonusId } = widthrawLockedAmount

    return {
      withdrawFee: new BigNumber(withdrawFee),
      selectedTokenMinWithdrawAmount,
      lockAmount,
      bonusId,
    }
  }, [withdrawFee, widthrawLockedAmount, selectedTokenMinWithdrawAmount])
}
