import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Text from 'components/Text'
import { ChainIdEnum } from 'config/constants/network'
import { USER_BANNED_ERROR_CODE } from 'config/constants/server'
import tokens from 'config/constants/tokens'
import { WalletType } from 'config/types/wallet'
import useModal from 'hooks/useModal'
import useSignMessage from 'hooks/useSignMessage'
import useWalletAccount from 'hooks/useWalletAccount'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import { formatUsername } from 'utils/stringHelper'
import { HunnyToast } from 'utils/toastify'
import ProvideTotpAuthCodeModal from 'views/Profile/Settings/components/SettingSecurity/2FA/ProvideTotpAuthCodeModal'
import { useWalletInfo } from 'state/auth/hooks'
import CircleLoader from 'components/Loader/CircleLoader'
import { ConfirmTipButtonProps } from './TraditionalConfirmTipbutton'

const WalletConfirmTipButton: React.FC<ConfirmTipButtonProps> = ({ details, onConfirmed }) => {
  const [signing, setSigning] = useState(false)
  const [submiting, setSubmiting] = useState(false)

  const { wallet, walletInfo, onConnectByWalletType, isWrongAddress } = useWalletInfo()
  const signmessage = useSignMessage(wallet.type)

  const accounts = useWalletAccount()
  const account = accounts[wallet?.type]
  const { message, receiver, amount, currency, network } = details
  const {
    userSettings: { isActive2FA, isEnable2FAForTip },
  } = useUserSettings()
  const [onPresentProvideTotpAuthCodeModal] = useModal(ProvideTotpAuthCodeModal)
  const { t } = useTranslation()

  const handleSentTip = async (acceptMessage, messageSignature, totpCode?: string) => {
    if (submiting) return
    setSubmiting(true)
    const response = await PaymentService.walletSendTip(acceptMessage, messageSignature, totpCode)
    if (response.code === 'success' && response?.data) {
      onConfirmed(response.data)
    }
    setSubmiting(false)
    return response
  }

  const handleSignWallet = useCallback(async () => {
    setSigning(true)

    const prepareSignMesageResponse = await PaymentService.prepareWalletTip({
      amount,
      currency: tokens[ChainIdEnum[network]]?.[currency],
      message,
      uid: receiver.uid,
      type: wallet.type === WalletType.EVM ? 'evm' : 'solana',
    })

    if (prepareSignMesageResponse.code === USER_BANNED_ERROR_CODE) {
      HunnyToast.error(
        t(
          'This feature has been temporarily suspended on your account. Please reach out to Customer Service for further details.',
        ),
      )
      return onConfirmed(null)
    }

    if (prepareSignMesageResponse?.data) {
      const messageSignature = await signmessage(prepareSignMesageResponse.data.acceptMessageText)

      if (messageSignature) {
        if (isActive2FA && isEnable2FAForTip) {
          onPresentProvideTotpAuthCodeModal({
            onTotpAuthCodeConfirmed: async (code) => {
              const response = handleSentTip(prepareSignMesageResponse.data.acceptMessage, messageSignature, code)
              return response
            },
          })
        } else {
          handleSentTip(prepareSignMesageResponse.data.acceptMessage, messageSignature)
        }
      }
    }

    setSigning(false)
  }, [])

  if (!account) {
    return (
      <Button onClick={onConnectByWalletType}>
        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Button>
    )
  }

  if (isWrongAddress) {
    return (
      <>
        <Flex
          alignItems="center"
          border={`1px solid ${colors.warning}`}
          padding="12px"
          borderRadius={theme.radii.small}
        >
          <Box mr="12px">
            <Icons.WarningIcon width="24px" fill={colors.warning} />
          </Box>

          <Text bold fontSize="12px" color="warning" textAlign="center" lineHeight="16px">
            {t('It seems your wallet connected is active different address, please switch to account {{account}}', {
              account: formatUsername(wallet.address),
            })}
          </Text>
        </Flex>

        {walletInfo.adapter instanceof WalletConnectConnector && (
          <Button mt="12px" width="100%" onClick={onConnectByWalletType}>
            <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
              <Trans>Switch account</Trans>
            </Text>
          </Button>
        )}
      </>
    )
  }

  return (
    <StyledConfirmButton onClick={handleSignWallet} disabled={signing || submiting}>
      {signing && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}

      <Text fontSize="14px" letterSpacing={-0.28} fontWeight={600}>
        <Trans>Send Tip</Trans>
      </Text>
    </StyledConfirmButton>
  )
}

const StyledConfirmButton = styled(Button)`
  width: 100%;
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  margin-bottom: 16px;
  height: 48px !important;
`

export default WalletConfirmTipButton
