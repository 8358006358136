import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { SentryHelper } from './sentryHelper'

export const setupNetwork = async (chainId: ChainIdEnum, library?: any) => {
  const network = NETWORK_MAP[chainId]
  if (!network) {
    return
  }

  const chainName = network.networkInfo.displayName
  const rpcUrls = network.rpcCollections
  const blockExplorerUrls = [network.blockExplorerUrls]

  const provider = library?.provider || window.ethereum

  if (provider) {
    if (chainId && provider) {
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        })
        return true
      } catch (switchError) {
        if ((switchError as any)?.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${chainId?.toString(16) || 0}`,
                  chainName,
                  nativeCurrency: {
                    name: network.networkInfo.native.name,
                    symbol: network.networkInfo.native.name,
                    decimals: network.networkInfo.native.decimals,
                  },
                  rpcUrls,
                  blockExplorerUrls,
                },
              ],
            })
            return true
          } catch (error) {
            SentryHelper.captureFeatureClientError({
              feature: 'Web3 wallet',
              error,
              options: {
                title: 'SetupNetwork failed',
                payload: {
                  chainId,
                },
              },
            })
            return false
          }
        }
        return false
      }
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined")
    return false
  }
}

const regex = [/rejected/, /denied/]

export const isReject = (e: any) => {
  return !!regex.find((r) => r.test(e.message || e.reason))
}
