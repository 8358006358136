/* eslint-disable no-async-promise-executor */
import { NETWORK_MAP } from 'config/constants/network'

import HunnyPlaySolana from 'config/constants/idls/SolanaContract.json'

import { AnchorProvider, BN, Program, setProvider } from '@coral-xyz/anchor'
import { getAssociatedTokenAddress as getAta, getOrCreateAssociatedTokenAccount, mintTo } from '@solana/spl-token'
import { WalletContextState } from '@solana/wallet-adapter-react'
import { Connection, Keypair, PublicKey, Transaction } from '@solana/web3.js'
import {
  DEV_SUPPORTED_METHODS,
  HUNNYPLAY_SENDER_ACCOUNT,
  SEED_ARRAY,
  SOL_USDT_MINT_KEYPAIR,
  SYSTEM_PROGRAM_ID,
  TOKEN_PROGRAM_ACCOUNT,
  TOKEN_PROGRAM_ID,
} from 'config/constants/solanaConfig'
import { Token } from 'config/types'
import { getDecimalAmount } from './formatBalance'
import { getRandomRpcFromList, getSimplerRpcProvider } from './providers'
import { SentryHelper } from './sentryHelper'

export interface ICreateAccountWithSeedProps {
  programId: PublicKey
  lamports?: number
  sender?: Keypair
  wallet?: any
}

export const getSolanaConfig = () => {
  return {
    programId: TOKEN_PROGRAM_ID,
    hunnyPlayStateAccount: HUNNYPLAY_SENDER_ACCOUNT,
    tokenProgram: TOKEN_PROGRAM_ACCOUNT,
    seedArray: SEED_ARRAY,
  }
}

export const getOrCreatedAta = async (mintAccount: PublicKey, owner: PublicKey, connection: Connection) => {
  try {
    const infoToken = await getOrCreateAssociatedTokenAccount(
      connection,
      Keypair.fromSecretKey(new Uint8Array(SOL_USDT_MINT_KEYPAIR)),
      mintAccount,
      owner,
      false,
      'confirmed',
    )
    return infoToken.address
  } catch (error) {
    SentryHelper.captureFeatureClientError({
      feature: 'SOLANA Network',
      error,
      options: {
        title: 'Get or Created Ata',
      },
    })
    return null
  }
}

export const findProgramAddress = async ({ programId, seeds }: ICreateAccountWithSeedProps & { seeds: string[] }) => {
  try {
    if (!programId) throw Error('Empty field')

    const parsedSeed = seeds?.map((item: any) => Buffer.from(item))
    const tokenAccounts = await PublicKey.findProgramAddress(parsedSeed, programId)

    return tokenAccounts.map((item) => item.toString())
  } catch (error) {
    SentryHelper.captureFeatureClientError({
      feature: 'SOLANA Network',
      error,
      options: {
        title: 'Find Program Address failed',
      },
    })
    return null
  }
}

export const getAssociatedTokenAddress = async (mint: PublicKey, owner: PublicKey, allowOwnerOffCurve?: boolean) => {
  try {
    const infoToken = getAta(mint, owner, allowOwnerOffCurve)
    return infoToken
  } catch (error) {
    SentryHelper.captureFeatureClientError({
      feature: 'SOLANA Network',
      error,
      options: {
        title: 'getAssociatedTokenAddress failed',
      },
    })
    return null
  }
}

export const getDepositAccounts = async (wallet: WalletContextState, _value: number, token: Token) => {
  try {
    const connection = getSimplerRpcProvider(token.network)
    const { seedArray, programId, hunnyPlayStateAccount, tokenProgram } = getSolanaConfig()
    const programAddress = await findProgramAddress({
      seeds: seedArray,
      programId,
    })

    const fromTokens = token.isNative
      ? token.address
      : await getAssociatedTokenAddress(new PublicKey(token.address), wallet.publicKey)

    const toToken = token.isNative
      ? token.address
      : await getAssociatedTokenAddress(new PublicKey(token.address), new PublicKey(programAddress[0]), true)

    if (DEV_SUPPORTED_METHODS['getOrCreateAssociatedAccount']) {
      await getOrCreatedAta(
        new PublicKey(DEV_SUPPORTED_METHODS['mintTo'].mintAddresses['USDT']),
        new PublicKey(programAddress[0]),
        connection,
      )
    }

    if (DEV_SUPPORTED_METHODS['mintTo'].active) {
      const associated = await getOrCreatedAta(
        new PublicKey(DEV_SUPPORTED_METHODS['mintTo'].mintAddresses['USDT']),
        wallet.publicKey,
        connection,
      )

      await mintTo(
        connection,
        Keypair.fromSecretKey(new Uint8Array(SOL_USDT_MINT_KEYPAIR)),
        new PublicKey(DEV_SUPPORTED_METHODS['mintTo'].mintAddresses['USDT']),
        new PublicKey(associated.toBase58()),
        Keypair.fromSecretKey(new Uint8Array(SOL_USDT_MINT_KEYPAIR)).publicKey,
        DEV_SUPPORTED_METHODS['mintTo'].mintValues['USDT'],
      )
    }

    return {
      user: wallet.publicKey,
      fromTokens,
      toTokens: toToken,
      mintAccount: token.address,
      pdaAccount: new PublicKey(programAddress[0]),
      tokenProgram,
      hunnyPlayStateAccount,
      systemProgram: SYSTEM_PROGRAM_ID,
      programId,
    }
  } catch (error) {
    SentryHelper.captureFeatureClientError({
      feature: 'SOLANA Network',
      error,
      options: {
        title: 'getDepositAccounts failed',
        payload: {
          wallet,
          value: _value,
          token,
        },
      },
    })

    return null
  }
}

export const getConnection = (rpc: string) => {
  return new Connection(rpc, {
    commitment: 'processed',
    confirmTransactionInitialTimeout: 60 * 10 * 1000,
  })
}

const getProvider = (wallet: WalletContextState, rpc: string) => {
  const connection = getConnection(rpc)
  const provider = new AnchorProvider(connection, wallet, {
    commitment: 'confirmed',
  })

  return provider
}

export const getProgram = ({ wallet, rpc }: { wallet: WalletContextState; rpc: string }) => {
  const provider = getProvider(wallet, rpc)
  setProvider(provider)

  const { programId } = getSolanaConfig()

  const program = new Program(HunnyPlaySolana as any, programId, provider)
  return { program, provider }
}

export const deposit = (token: Token, _amount: string, wallet: WalletContextState): Promise<string | null> =>
  new Promise(async (resolve, reject) => {
    try {
      const amount = new BN(getDecimalAmount(_amount, token.decimals).toNumber())

      const { program, provider } = getProgram({
        wallet,
        rpc: getRandomRpcFromList(NETWORK_MAP?.[token.network].rpcCollections || []) || '',
      })

      const accounts = await getDepositAccounts(wallet, amount.toNumber(), token)
      const tx = await program.methods.deposit(amount).accounts(accounts).instruction()

      const txs = new Transaction()
      const blockhash = await provider.connection.getLatestBlockhash('finalized')

      txs.add(tx)
      txs.recentBlockhash = blockhash.blockhash
      txs.feePayer = wallet.publicKey

      const signed_tx = await provider.wallet.signTransaction(txs)

      const txid = await provider.connection.sendRawTransaction(signed_tx.serialize())

      resolve(txid)
    } catch (error: any) {
      SentryHelper.captureFeatureClientError({
        feature: 'SOLANA Network',
        error,
        options: {
          title: 'Deposit solana failed',
          payload: {
            sender: wallet.publicKey?.toString(),
            token: token.code,
            chainId: token.network,
            amount: _amount,
          },
        },
      })
      reject(error)
    }
  })
