import { useWallet } from '@solana/wallet-adapter-react'
import { WalletType } from 'config/types/wallet'
import { ethers } from 'ethers'
import { useTranslation } from 'react-i18next'
import { SentryHelper } from 'utils/sentryHelper'
import { HunnyToast } from 'utils/toastify'
import { useSignMessage as useWagmiSignMessage } from 'wagmi'

const useSignMessage = (type: WalletType): ((message: string) => Promise<string>) => {
  const { signMessage: solSignMessage } = useWallet()
  const { t } = useTranslation()
  const { signMessageAsync } = useWagmiSignMessage()

  const _handleEtherWalletSignMessage = async (_message: string) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve) => {
      try {
        const signMessage = await signMessageAsync({
          message: _message,
        })

        if (signMessage) {
          return resolve(signMessage)
        }
      } catch (error: any) {
        if (error?.code === -32603 || error?.code === -32000 || error?.code === 4001) {
          HunnyToast.warn(
            t('Rejected'),
            t('The request to sign the message on your wallet was rejected. Please try again.'),
            {
              toastId: 'sign-message-failed',
            },
          )
        } else {
          HunnyToast.error(t('Error'), error.message, {
            toastId: 'sign-message-failed',
          })
        }
      }
      resolve('')
    })
  }

  const _handleSolWalletSignMessage = async (inputMessage: string) => {
    try {
      const message = new TextEncoder().encode(inputMessage)
      const signature = await solSignMessage(message)

      if (signature) {
        const signatureTextString = ethers.utils.hexlify(signature)
        return signatureTextString
      }
    } catch (error) {
      HunnyToast.warn(
        t('Rejected'),
        t('The request to sign the message on your wallet was rejected. Please try again.'),
        {
          toastId: 'sign-message-failed',
        },
      )

      SentryHelper.captureFeatureClientError({
        feature: 'SolanaConnector',
        error,
        options: { title: 'Solana sign message error' },
      })
      return null
    }
  }

  if (type === WalletType.EVM || type === WalletType.BSC) {
    return _handleEtherWalletSignMessage
  }
  if (type === WalletType.SOL) {
    return _handleSolWalletSignMessage
  }
}

export default useSignMessage
