import { APIEndpointEnum } from 'config/constants/server'
import { CurrentReward, DailyRewardCampaign } from 'config/types/bonus/DailyReward'
import { OtherTransactionFilters } from 'views/Profile/Transactions/types'
import { formatFitlerTransaction } from 'utils/transaction'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest } from './types'

class DailyRewardService extends HunnyPlayRequest {
  public getCampaign(includeUserData: boolean): HunnyRequest<BaseResponse<DailyRewardCampaign>> {
    const result = this._post(
      includeUserData ? APIEndpointEnum.DailyCheckinHasSession : APIEndpointEnum.DailyCheckin,
      null,
      { authRequest: includeUserData },
    )

    return result
  }

  public getDailyCheckinHistory(
    offset = 0,
    limit = 1000,
    filter: OtherTransactionFilters,
  ): HunnyRequest<BaseResponse<any>> {
    const params: any = {
      paging: { limit, offset },
      filter: {
        ...formatFitlerTransaction(filter),
      },
    }
    const result = this._post(APIEndpointEnum.DailyCheckinHistory, params, { authRequest: true })

    return result
  }

  public claimReward(currentReward: CurrentReward): Promise<BaseResponse<boolean>> {
    const result = this._request(
      APIEndpointEnum.DailyCheckinClaim,
      {
        checkin_type: currentReward.packageCode,
      },
      { authRequest: true },
    )
    return result
  }
}

const instance = new DailyRewardService()
export default instance
