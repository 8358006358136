import { DEFAULT_TIME } from 'config/constants'
import { TimeDisplayValuesType } from 'config/types'

export const formatDisplayDateTime = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  return `${day}/${month}/${year} ${hour}:${minute}`
}

export const formatDisplayFullDateTime = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')
  return `${day}/${month}/${year} ${hour}:${minute}:${seconds}`
}

export const formatDisplayTime = (date: Date) => {
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  return `${hour}:${minute}`
}

export const formatDisplayDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  const dateDisplay = `${day}/${month}/${year}`

  return dateDisplay
}

export const isEqualDate = (date1: Date, date2: Date) => {
  return (
    date1.getMonth() == date2.getMonth() &&
    date2.getDate() === date1.getDate() &&
    date2.getFullYear() === date1.getFullYear()
  )
}
export const parseServerTime = (time: number | Date | string) => {
  const parsedTime = new Date(time).getTime()
  return Math.floor(parsedTime / 1000)
}
export function generateTimeDisplay(
  to: number,
  serverTimeMargin: number,
  displayFullHourFormat?: boolean,
): TimeDisplayValuesType {
  const rightJustNow = new Date().getTime() + serverTimeMargin

  const runway = to - rightJustNow
  let stateObj

  if (displayFullHourFormat) {
    stateObj = {
      hours: Math.floor(runway / (1000 * 60 * 60)),
      minutes: Math.floor((runway % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((runway % (1000 * 60)) / 1000),
    }
  } else {
    stateObj = {
      days: Math.floor(runway / (1000 * 60 * 60 * 24)),
      hours: Math.floor((runway % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((runway % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((runway % (1000 * 60)) / 1000),
    }
  }

  if ((stateObj.days || 0) <= 0 && stateObj.hours <= 0 && stateObj.minutes <= 0 && stateObj.seconds <= 0) {
    return DEFAULT_TIME
  }

  return stateObj
}

export const monthNames = [
  {
    code: '01',
    value: 'January',
  },
  {
    code: '02',
    value: 'February',
  },
  {
    code: '03',
    value: 'March',
  },
  {
    code: '04',
    value: 'April',
  },
  {
    code: '05',
    value: 'May',
  },
  {
    code: '06',
    value: 'June',
  },
  {
    code: '07',
    value: 'July',
  },
  {
    code: '08',
    value: 'August',
  },
  {
    code: '09',
    value: 'September',
  },
  {
    code: 10,
    value: 'October',
  },
  {
    code: 11,
    value: 'November',
  },
  {
    code: 12,
    value: 'December',
  },
]

export const isPass = (dateTime: number | string | Date): boolean => {
  const parsedDate = new Date(dateTime)
  return parsedDate.getTime() < Date.now()
}
