import Box from 'components/Box/Box'
import styled from 'styled-components'

export const PromotionContentCSS = styled(Box)`
  * {
    margin: revert;
    list-style: revert;
    padding: revert;
    line-height: 1.15;
  }

  a {
    text-decoration: revert;
  }
  img {
    max-width: 100%;
  }

  figure {
    margin-left: 0;
    margin-right: 0;
  }

  table {
    max-width: 100%;
    border-style: solid;

    span {
      white-space: break-spaces;
      word-break: break-word;
    }
  }

  table td {
    border: 1px solid ${({ theme }) => theme.colors.text};
  }

  tr {
    display: table-row;
    vertical-align: middle;
  }
  td {
    display: table-cell;
    vertical-align: middle;
    border-style: solid;
  }
`
