import Box from 'components/Box/Box'
import Button from 'components/Button'
import FormControl from 'components/FormControl'
import { StyledControlContainer } from 'components/FormControl/styled'
import Image from 'components/Image'
import NumericalInput from 'components/Input/NumericalInput'
import { InputContainer, InputLabel } from 'components/Input/styled'
import CircleLoader from 'components/Loader/CircleLoader'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenSelect from 'components/TokenSelect'
import FormValidator from 'config/constants/formValidator'
import { ChainIdEnum, FIAT_NETWORK } from 'config/constants/network'
import { Token } from 'config/types'
import { DepositFiatPaymentMethod, DepositFiatPaymentTypeEnums, FiatMethodCategory } from 'config/types/payment'
import { ValidationError } from 'config/types/validator'
import useForm from 'hooks/useForm'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useIsTelegramOrigin } from 'hooks/useTelegram'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { useListTokens, useTokenInfo } from 'state/app/hooks'
import { usePaymentSelectedFiatCurrency } from 'state/session/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import base from 'theme/base'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'
import GameInfoMessage from 'views/GameDetails/GameInfoMessage'
import { useWaitingDepositToActiveBonus } from '../DepositCryptos/hooks'
import WaitingDepositBonusList from '../WaitingDepositBonusList'
import DepositInProgressModal from './DepositInProgressModal'
import PaymentMethodBlockingModal from './PaymentMethodBlockingModal'
import PaymentMethodCard, { Loader, PaymentMethodCardContainer } from './PaymentMethodCard'

const DepositByFiat = () => {
  const { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired } = useWaitingDepositToActiveBonus()
  const listFiatCurrencies = useListTokens(ChainIdEnum.FIAT)
  const { execute } = useRequest()
  const [onPresentDepositFiatInProgressModal] = useModal(DepositInProgressModal)
  const [onPresentPaymentMethodBlockingModal] = useModal(PaymentMethodBlockingModal)

  const userDefaultSelectCurrency = usePaymentSelectedFiatCurrency()

  const [selectedCurrency, setSelectedCurrency] = useState<Token>(userDefaultSelectCurrency)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<DepositFiatPaymentMethod>(null)
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<[string, DepositFiatPaymentMethod[]][]>(null)
  const [filterOnlineBankings, setFilterOnlineBankings] = useState<string>('')
  const [isFetchingFiatMethods, setIsFetchingFiatMethods] = useState(false)
  const [isFetchingMerchantSiteUrl, setIsFetchingMerchantSiteUrl] = useState(false)
  const { t } = useTranslation()
  const currencyInfo = useTokenInfo(selectedCurrency)
  const isOriginTelegram = useIsTelegramOrigin()
  const isEnableFormSubmit = useMemo(() => isOriginTelegram || isMobile, [isOriginTelegram, isMobile])

  const { states, controls, isValid } = useForm({
    depositAmount: {
      value: '',
      validators: [
        FormValidator.required,
        FormValidator.min(selectedPaymentMethod?.minAmount),
        FormValidator.max(selectedPaymentMethod?.maxAmount),
      ],
    },
  })

  useEffect(() => {
    const fetch = async () => {
      setIsFetchingFiatMethods(true)
      const response = await execute(PaymentService.getDepositFiatBankMethodList({ currency: selectedCurrency.code }))
      if (response?.data) {
        setPaymentMethodOptions(response.data)
      }

      setIsFetchingFiatMethods(false)
    }

    fetch()
  }, [selectedCurrency])

  const refForm = useRef<HTMLDivElement>(null)

  const handleDepositFiat = useCallback(async () => {
    setIsFetchingMerchantSiteUrl(true)
    const response = await PaymentService.depositByFiat({
      amount: Number(states.depositAmount.value),
      bankCode: selectedPaymentMethod.code,
      currency: selectedCurrency.code,
    })

    if (response?.data) {
      if (!response.data.content && response.data.pendingTransaction) {
        onPresentPaymentMethodBlockingModal({
          paymentFiatTransaction: {
            ...response.data.pendingTransaction,
            fiat: { bankName: selectedPaymentMethod.name },
          },
        })
        setIsFetchingMerchantSiteUrl(false)
        return
      }

      const depositDetailResponse = await execute(PaymentService.getDepositByTxnHash(response.data.txnHash))

      onPresentDepositFiatInProgressModal({
        paymentFiatTransaction: {
          amount: {
            amount: states.depositAmount.value,
            token: selectedCurrency,
          },
          createdAt: depositDetailResponse.data.createTime,
          txnHash: depositDetailResponse.data.txnHash,
          method: selectedPaymentMethod.type,
          name: selectedPaymentMethod.name,
        },
        transactionDetail: depositDetailResponse.data,
      })

      if (isEnableFormSubmit && refForm.current) {
        refForm.current.innerHTML = response.data.content

        const form = refForm.current.querySelector('form')
        if (form?.submit) {
          form.submit()
        }
      } else {
        const newWindow = (window as any)?.open()
        if (newWindow) {
          newWindow.document.write(response.data.content)
          newWindow.document.close()
        }
      }
    }

    setIsFetchingMerchantSiteUrl(false)
  }, [selectedPaymentMethod, selectedCurrency, states.depositAmount])

  const formatErrors = (errors: ValidationError[]) => {
    if (errors[0] === ValidationError.NotEnough || errors[0] === ValidationError.Insufficient) {
      return t('Deposit amount has to be between {{ minAmount }} {{ currency }} and {{ maxAmount }} {{ currency }}', {
        minAmount: getFullDisplayBalance(selectedPaymentMethod.minAmount, 0, 5),
        maxAmount: getFullDisplayBalance(selectedPaymentMethod.maxAmount, 0, 5),
        currency: selectedPaymentMethod.currency,
      })
    }
    if (errors[0] === ValidationError.Required) {
      return <Trans>Deposit amount is required</Trans>
    }
  }

  return (
    <>
      {isEnableFormSubmit && <Box height="0px" ref={refForm} />}

      <StyledContainer openType="grow">
        <StyledControlContainer state={null} mt="10px">
          <InputLabel>
            <Trans>Deposit Currency</Trans>:
          </InputLabel>

          <TokenSelect
            options={listFiatCurrencies}
            onTokenChange={(token) => {
              setSelectedPaymentMethod(null)
              setSelectedCurrency(token)
              controls.depositAmount.onValueChanged('')
            }}
            value={selectedCurrency}
          />
        </StyledControlContainer>

        {((!paymentMethodOptions || !paymentMethodOptions?.length) && !isFetchingFiatMethods) ||
          (currencyInfo.disabledDeposit && (
            <GameInfoMessage
              variant="error"
              maxWidth="100% !important"
              my="12px"
              message={t('Deposit with {{tokenName}}({{network}}) has been suspended.', {
                tokenName: selectedCurrency.name,
                network: FIAT_NETWORK.networkInfo.shortName,
              })}
            />
          ))}
        {paymentMethodOptions?.length && !currencyInfo.disabledDeposit && (
          <>
            <Box>
              <Column style={{ gap: '12px' }}>
                <InputLabel
                  fontSize="12px"
                  fontWeight={500}
                  letterSpacing={-0.24}
                  lineHeight="normal"
                  color="textSubtle"
                >
                  <Trans>Choose a payment method</Trans>:
                </InputLabel>
                {!isFetchingFiatMethods &&
                  !selectedPaymentMethod &&
                  paymentMethodOptions?.length > 0 &&
                  paymentMethodOptions.map(([paymentMethodType, paymentMethodList]) => {
                    const paymentMethodAsset = FiatMethodCategory.find((method) =>
                      method.methods.includes(Number(paymentMethodType)),
                    )
                    if (paymentMethodAsset)
                      return (
                        <>
                          <PaymentMethodCardContainer
                            title={paymentMethodAsset.title}
                            Icon={paymentMethodAsset.icon}
                            maxHeight={
                              (paymentMethodType as unknown as DepositFiatPaymentTypeEnums) ==
                              DepositFiatPaymentTypeEnums.OnlineBanking
                                ? '388px'
                                : '200px'
                            }
                            overflowY="auto"
                            onBankSearchChanged={
                              (paymentMethodType as unknown as DepositFiatPaymentTypeEnums) ==
                              DepositFiatPaymentTypeEnums.OnlineBanking
                                ? (value) => setFilterOnlineBankings(value)
                                : null
                            }
                          >
                            {paymentMethodList
                              ?.filter((item) =>
                                item.type == DepositFiatPaymentTypeEnums.OnlineBanking
                                  ? item.name.toLocaleLowerCase().includes(filterOnlineBankings.toLocaleLowerCase())
                                  : item,
                              )
                              ?.map((item) => (
                                <PaymentMethodCard
                                  method={item}
                                  onItemSelected={(selectedItem) => setSelectedPaymentMethod(selectedItem)}
                                />
                              ))}
                          </PaymentMethodCardContainer>
                          <Divider />
                        </>
                      )
                    return null
                  })}
                {isFetchingFiatMethods && <Loader />}
              </Column>
              {selectedPaymentMethod && (
                <Box>
                  <RowBetween
                    borderRadius={base.radii.tiny}
                    border={`1px solid ${colors.stroke}`}
                    padding="12px"
                    maxHeight="48px"
                    onClick={() => setSelectedPaymentMethod(null)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <RowCenter>
                      <Box width={'34px'} height={'32px'} mr="4px">
                        <Image src={selectedPaymentMethod.thumbnail} width={32} height={30} />
                      </Box>
                      <Column>
                        <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28} lineHeight="normal">
                          {selectedPaymentMethod.name}
                        </Text>
                      </Column>
                    </RowCenter>
                    <Icons.ArrowDownIcon size="20px" />
                  </RowBetween>
                  <WaitingDepositBonusList
                    mt="20px"
                    selectedToken={selectedCurrency}
                    bonuses={bonuses}
                    handleSelectedBonus={handleSelectedBonus}
                    selectedBonus={selectedBonus}
                    isSelectedBonusExpired={isSelectedBonusExpired}
                  />
                  <Box mt="20px">
                    <FormControl label={t('Amount')} formatErrorMessage={formatErrors} state={states.depositAmount}>
                      <InputContainer paddingY="14px" px="20px" pr="0px" height="48px">
                        <StyledTokenInput
                          autoFocus={false}
                          tabIndex={0}
                          value={states.depositAmount.value}
                          onValueChanged={controls.depositAmount.onValueChanged}
                          onErrorChanged={controls.depositAmount.onErrorChanged}
                          validators={controls.depositAmount.validators}
                          pattern="^[0-9]*$"
                          onBlur={() => {
                            controls.depositAmount.onValueChanged(states.depositAmount.value.split('.')[0])
                          }}
                          placeholder={''}
                          validateOnchange
                        />
                        <Text
                          fontSize="16px"
                          fontWeight={500}
                          letterSpacing={-0.28}
                          lineHeight="normal"
                          padding="15px 20px"
                        >
                          {selectedCurrency.name}
                        </Text>
                      </InputContainer>
                    </FormControl>
                    <Box mt="12px">
                      <Text my="4px" color="textAlt1" fontSize="10px" fontWeight={500} letterSpacing={-0.2}>
                        <Trans>Min Deposit</Trans>: {getFullDisplayBalance(selectedPaymentMethod.minAmount)}{' '}
                        {selectedCurrency.code}
                      </Text>
                      <Text color="textAlt1" fontSize="10px" fontWeight={500} letterSpacing={-0.2}>
                        <Trans>Max Deposit</Trans>: {getFullDisplayBalance(selectedPaymentMethod.maxAmount)}{' '}
                        {selectedCurrency.code}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Button
              width="100%"
              variant="primary"
              onClick={handleDepositFiat}
              disabled={!isValid || !states.depositAmount.value || !selectedPaymentMethod || isFetchingMerchantSiteUrl}
            >
              <RowCenter>
                {isFetchingMerchantSiteUrl && (
                  <Box mr="12px">
                    <CircleLoader />
                  </Box>
                )}
                <Text fontSize="14px" fontWeight={600} lineHeight="normal" letterSpacing={-0.28}>
                  {isFetchingMerchantSiteUrl && <Trans>Redirecting</Trans>}
                  {!isFetchingMerchantSiteUrl && (
                    <>
                      {selectedPaymentMethod && <Trans>Deposit via {{ methodName: selectedPaymentMethod.name }}</Trans>}
                      {!selectedPaymentMethod && <Trans>Deposit</Trans>}
                    </>
                  )}
                </Text>
              </RowCenter>
            </Button>
            <Box padding="12px 20px" borderRadius={base.radii.small} mt="22px" background={colors.backgroundAlt8}>
              <Text color="textSubtle" fontSize="12px" fontWeight={500} lineHeight="20px" letterSpacing={-0.24}>
                <Trans>
                  You will be redirected to a third-party site verified by HunnyPlay for a secure and trustworthy
                  browsing experience
                </Trans>
              </Text>
            </Box>
          </>
        )}
      </StyledContainer>
    </>
  )
}

export default DepositByFiat

const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 450px;
  }
`

const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background: ${({ theme: { colors } }) => colors.buttonBackgroundAlt2};
`

const StyledTokenInput = styled(NumericalInput)`
  text-align: right;
  height: auto !important;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.text};
`
